import React, { memo } from 'react';
import { Skeleton } from '@mui/material';
import placeholder from '../media/placeholder.svg';
import '../styles/CourseSkeleton.css';

const CourseSkeleton = memo(function CourseSkeleton(): React.ReactElement {
    return (
        <div className="course-wrapper">
            <Skeleton
                variant="rectangular"
                animation="wave"
                className="course-image-skeleton"
            >
                <img src={placeholder} alt="placeholder" className="course-image"/>
            </Skeleton>
            <div className="course-information">
                <div className="course-title">
                    <Skeleton
                        variant="rounded"
                        animation="wave"
                        className="course-title-skeleton"
                    >
                        <span style={{visibility: 'hidden'}}>Placeholder</span>
                    </Skeleton>
                </div>
                <div className="course-description">
                    <Skeleton
                        variant="rounded"
                        animation="wave"
                        className="course-description-skeleton"
                    >
                        <span style={{visibility: 'hidden'}}>Placeholder</span>
                    </Skeleton>
                </div>
            </div>
            <div className="course-details">
                <div className="target-audience-container">
                    <div className="course-information-label">
                        <Skeleton
                            variant="rounded"
                            animation="wave"
                            className="course-information-label-skeleton"
                        >
                            <span style={{visibility: 'hidden'}}>Placeholder</span>
                        </Skeleton>
                    </div>
                    <div className="course-information-value">
                        <Skeleton
                            variant="rounded"
                            animation="wave"
                            className="course-information-value-skeleton"
                        >
                            <span style={{visibility: 'hidden'}}>Placeholder</span>
                        </Skeleton>
                    </div>
                </div>
                <div className="course-category-container">
                    <div className="course-information-label">
                        <Skeleton
                            variant="rounded"
                            animation="wave"
                            className="course-information-label-skeleton"
                        >
                            <span style={{visibility: 'hidden'}}>Placeholder</span>
                        </Skeleton>
                    </div>
                    <div className="course-information-value">
                        <Skeleton
                            variant="rounded"
                            animation="wave"
                            className="course-information-value-skeleton"
                        >
                            <span style={{visibility: 'hidden'}}>Placeholder</span>
                        </Skeleton>
                    </div>
                </div>
                <div className="format-container">
                    <div className="course-information-label">
                        <Skeleton
                            variant="rounded"
                            animation="wave"
                            className="course-information-label-skeleton"
                        >
                            <span style={{visibility: 'hidden'}}>Placeholder</span>
                        </Skeleton>
                    </div>
                    <div className="course-information-value">
                        <Skeleton
                            variant="rounded"
                            animation="wave"
                            className="course-information-value-skeleton"
                        >
                            <span style={{visibility: 'hidden'}}>Placeholder</span>
                        </Skeleton>
                    </div>
                </div>
                <div className="duration-container">
                    <div className="course-information-label">
                        <Skeleton
                            variant="rounded"
                            animation="wave"
                            className="course-information-label-skeleton"
                        >
                            <span style={{visibility: 'hidden'}}>Placeholder</span>
                        </Skeleton>
                    </div>
                    <div
                        className="course-information-value">
                        <Skeleton
                            variant="rounded"
                            animation="wave"
                            className="course-information-value-skeleton"
                        >
                            <span style={{visibility: 'hidden'}}>Placeholder</span>
                        </Skeleton>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default CourseSkeleton;
