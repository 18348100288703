import React, { memo, useCallback } from 'react';
import { useNavigate } from '@tanstack/react-router';
import Button from 'react-bootstrap/Button';
import '../styles/CenteredHero.css';

const CenteredHero = memo(function CenteredHero(
    {
        title,
        subtitle,
        firstLabel,
        firstUrl,
        secondLabel,
        secondUrl,
    }: {
        title: string,
        subtitle: string,
        firstLabel: string,
        firstUrl: string,
        secondLabel: string,
        secondUrl: string,
    }
): React.ReactElement {
    const navigate = useNavigate({ from: window.location.pathname });
    const firstLinkExternal: boolean = firstUrl.includes('http:') || firstUrl.includes('https:');
    const secondLinkExternal: boolean = secondUrl.includes('http:') || secondUrl.includes('https:');

    const firstButtonHandler = useCallback((): void => {
        if (firstLinkExternal) {
            window.location.href = firstUrl;
            return;
        }

        navigate({ to: firstUrl });
    }, [firstLinkExternal, firstUrl, navigate]);

    const secondButtonHandler = useCallback((): void => {
        if (secondLinkExternal) {
            window.location.href = secondUrl;
            return;
        }

        navigate({ to: secondUrl });
    }, [secondLinkExternal, secondUrl, navigate]);

    return (
        <div className="centered-hero-container">
            <div className="centered-hero-title">{title}</div>
            <div className="centered-hero-subtitle">{subtitle}</div>
            <div className="centered-hero-buttons">
                <Button
                    variant="primary"
                    onClick={firstButtonHandler}
                >{firstLabel}</Button>
                <Button
                    variant="primary"
                    onClick={secondButtonHandler}
                >{secondLabel}</Button>
            </div>
        </div>
    );
});

export default CenteredHero;
