import React, { memo, useState } from 'react';
import {
    Backdrop,
    CircularProgress,
    Theme,
} from '@mui/material';

const ScormPlayer = memo(function ScormPlayer(
    {
        scormTitle,
        scormUrl,
    }: {
        scormTitle: string,
        scormUrl: string,
    }
) {
    const [iframeLoading, setIframeLoading] = useState<boolean>(true);

    return (
        <>
            <Backdrop open={iframeLoading}
                      sx={{zIndex: (theme: Theme) => theme.zIndex.drawer + 1}}
            >
                <CircularProgress sx={{color: 'var(--background-color)'}}/>
            </Backdrop>
            <div className={'course-iframe-container' + (iframeLoading ? '' : ' visible')}>
                <iframe
                    className="course-iframe"
                    title={scormTitle}
                    src={scormUrl}
                    onLoad={() => setIframeLoading(false)}
                    width="100%"
                    height="100%"
                />
            </div>
        </>
    );
});

export default ScormPlayer;
