import React, { memo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@tanstack/react-router';
import { Skeleton } from '@mui/material';
import type { Course } from '../../../server/types/core';
import placeholder from '../media/placeholder.svg';

const CourseItem = memo(function CourseItem(
    {
        course,
    }: {
        course?: Course,
    }
): React.ReactElement {
    const { t } = useTranslation();
    const [imageLoading, setImageLoading] = useState(true);

    const getStatusText = useCallback((status: string): string => {
        switch (status) {
            case 'not attempted':
            case 'incomplete':
            case 'signed up':
                return 'inProgress';
            case 'passed':
            case 'complete':
            case 'completed':
                return 'completed';
            case 'failed':
                return 'failed';
            default:
                return '';
        }
    }, []);

    if (!course) {
        return (
            <Link to="/courses/$courseId" params={{courseId: 'placeholder'}} className="course-item-container">
                <img
                    src={placeholder}
                    alt="placeholder"
                    className="course-item-thumbnail"
                />
                <div className="course-item-wrapper">
                    <div className="course-item-title">Placeholder</div>
                    <div className="course-item-description">Placeholder</div>
                    <div className="course-item-details">
                        <div className="course-item-price">Placeholder</div>
                        <div className="course-item-duration">Placeholder</div>
                    </div>
                    <div className="course-item-footer">
                        <div className="course-item-badges">
                            <div className="course-item-target-audience">Placeholder</div>
                            <div className="course-item-format">Placeholder</div>
                            <div className="course-item-course-category">Placeholder</div>
                        </div>
                    </div>
                </div>
                <div className="course-item-status">Placeholder</div>
            </Link>
        );
    }

    const {
        id,
        title,
        description,
        hasPrice,
        price,
        duration,
        durationType,
        targetAudience,
        format,
        thumbnail,
        courseCategory,
        status,
    }: Course = course;

    return (
        <Link to="/courses/$courseId" params={{courseId: id!.toString()}} className="course-item-container">
            <div className="course-item-thumbnail-container">
                {imageLoading &&
                    <Skeleton animation="wave" className="course-item-thumbnail-skeleton">
                        <img
                            src={placeholder}
                            alt="placeholder"
                            className="course-item-thumbnail"
                        />
                    </Skeleton>
                }
                <img
                    src={thumbnail as string}
                    alt={title}
                    onLoad={() => setImageLoading(false)}
                    className={`course-item-thumbnail${imageLoading ? ' loading' : ''}`}
                />
            </div>
            <div className="course-item-wrapper">
                <div className="course-item-title">{title}</div>
                <div className="course-item-description">{description}</div>
                <div className="course-item-details">
                    <div className="course-item-price">{hasPrice ? `${price} NOK` : t('freeCourse')}</div>
                    <div className="course-item-duration">{`${duration} ${t(durationType)}`}</div>
                </div>
                <div className="course-item-footer">
                    <div className="course-item-badges">
                        {(targetAudience as string[])?.map((audience: string, index: number) => (
                            <div className="item-badge badge-target-audience" key={index}>{t(audience)}</div>
                        ))}
                        {format !== 'digital' && <div className="item-badge badge-format">{t(format)}</div>}
                        <div className="item-badge badge-course-category">{t(courseCategory)}</div>
                    </div>
                </div>
            </div>
            {status &&
                <div className={`course-item-status ${getStatusText(status)}`}>{t(getStatusText(status))}</div>
            }
        </Link>
    );
});

export default CourseItem;
