import {
    getGridStringOperators,
    getGridNumericOperators,
    GridFilterOperator,
    GridColDef,
    GridActionsCellItem,
    GridRowParams,
} from '@mui/x-data-grid';
import { Delete, Edit, Restore, Visibility } from '@mui/icons-material';
import { formatTime } from '../Time';
import type { DiplomaFile } from '../../../../server/types/admin/core';

// TODO: Simplify by entity
function getCoursesColumns(
    translateHandler: Function,
    navigateHandler: Function,
    dialogHandler: Function,
): GridColDef[] {
    const [
        stringFilterOperators,
        numericFilterOperators,
    ]: GridFilterOperator[][] = getFilterOperators();

    return [
        {
            field: 'id',
            headerName: 'ID',
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'title',
            headerName: translateHandler('title'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'description',
            headerName: translateHandler('description'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'targetAudience',
            headerName: translateHandler('targetAudience'),
            valueGetter: (value: string) => translateHandler(value),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'duration',
            headerName: translateHandler('duration'),
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'durationType',
            headerName: translateHandler('durationType'),
            valueGetter: (value: string) => translateHandler(value),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'hasPrice',
            headerName: translateHandler('hasPrice'),
            type: 'boolean',
            flex: 1,
        },
        {
            field: 'price',
            headerName: translateHandler('price'),
            valueGetter: (value: number) => !value ? '' : value,
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'format',
            headerName: translateHandler('format'),
            valueGetter: (value: string) => translateHandler(value),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'signupLimit',
            headerName: translateHandler('signupLimit'),
            valueGetter: (value: number) => !value ? '' : value,
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'location',
            headerName: translateHandler('location'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'courseType',
            headerName: translateHandler('courseType'),
            valueGetter: (value: string) => translateHandler(value),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'startTime',
            headerName: translateHandler('startTime'),
            valueGetter: (value: string): string | Date => !value ? '' : value,
            valueFormatter: (value: string): string => !value
                ? ''
                : formatTime(value, ','),
            type: 'dateTime',
            flex: 1,
        },
        {
            field: 'richTextDescription',
            headerName: translateHandler('richTextDescription'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'courseCategory',
            headerName: translateHandler('courseCategory'),
            valueGetter: (value: string) => translateHandler(value),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'isPublic',
            headerName: translateHandler('isPublic'),
            type: 'boolean',
            flex: 1,
        },
        {
            field: 'assignedTo',
            headerName: translateHandler('assignedTo'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'linkedId',
            headerName: translateHandler('linkedCourseId'),
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'assignedToGroups',
            headerName: translateHandler('assignedToGroups'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'parentId',
            headerName: translateHandler('parentCourseId'),
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'diplomaFile',
            headerName: translateHandler('diplomaFile'),
            valueGetter: (value: DiplomaFile | null): string => !value ? '' : value.name,
            flex: 1,
        },
        {
            field: 'createdAt',
            headerName: translateHandler('createdAt'),
            valueGetter: (value: string): string | Date => !value ? '' : value,
            valueFormatter: (value: string): string => !value
                ? ''
                : formatTime(value, ','),
            type: 'dateTime',
            flex: 1,
        },
        {
            field: translateHandler('actions'),
            type: 'actions',
            flex: 1,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                    icon={<Delete />}
                    title={translateHandler('delete')}
                    label={translateHandler('delete')}
                    onClick={() => dialogHandler(true, params.id)}
                />,
                <GridActionsCellItem
                    icon={<Edit />}
                    title={translateHandler('edit')}
                    label={translateHandler('edit')}
                    onClick={() => navigateHandler(`/manage-courses/edit/${params.id}`)}
                />,
            ],
        },
    ];
}

function getDeletedCoursesColumns(
    translateHandler: Function,
    dialogHandler: Function,
): GridColDef[] {
    const [
        stringFilterOperators,
        numericFilterOperators,
    ]: GridFilterOperator[][] = getFilterOperators();

    return [
        {
            field: 'id',
            headerName: 'ID',
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'title',
            headerName: translateHandler('title'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'description',
            headerName: translateHandler('description'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'targetAudience',
            headerName: translateHandler('targetAudience'),
            valueGetter: (value: string) => translateHandler(value),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'duration',
            headerName: translateHandler('duration'),
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'durationType',
            headerName: translateHandler('durationType'),
            valueGetter: (value: string) => translateHandler(value),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'hasPrice',
            headerName: translateHandler('hasPrice'),
            type: 'boolean',
            flex: 1,
        },
        {
            field: 'price',
            headerName: translateHandler('price'),
            valueGetter: (value: number) => !value ? '' : value,
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'format',
            headerName: translateHandler('format'),
            valueGetter: (value: string) => translateHandler(value),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'signupLimit',
            headerName: translateHandler('signupLimit'),
            valueGetter: (value: number) => !value ? '' : value,
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'location',
            headerName: translateHandler('location'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'courseType',
            headerName: translateHandler('courseType'),
            valueGetter: (value: string) => translateHandler(value),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'startTime',
            headerName: translateHandler('startTime'),
            valueGetter: (value: string): string | Date => !value ? '' : value,
            valueFormatter: (value: string): string => !value
                ? ''
                : formatTime(value, ','),
            type: 'dateTime',
            flex: 1,
        },
        {
            field: 'richTextDescription',
            headerName: translateHandler('richTextDescription'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'courseCategory',
            headerName: translateHandler('courseCategory'),
            valueGetter: (value: string) => translateHandler(value),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'isPublic',
            headerName: translateHandler('isPublic'),
            type: 'boolean',
            flex: 1,
        },
        {
            field: 'assignedTo',
            headerName: translateHandler('assignedTo'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'linkedId',
            headerName: translateHandler('linkedCourseId'),
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'assignedToGroups',
            headerName: translateHandler('assignedToGroups'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'parentId',
            headerName: translateHandler('parentCourseId'),
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'diplomaFile',
            headerName: translateHandler('diplomaFile'),
            valueGetter: (value: DiplomaFile | null): string => !value ? '' : value.name,
            flex: 1,
        },
        {
            field: 'createdAt',
            headerName: translateHandler('createdAt'),
            valueGetter: (value: string): string | Date => !value ? '' : value,
            valueFormatter: (value: string): string => !value
                ? ''
                : formatTime(value, ','),
            type: 'dateTime',
            flex: 1,
        },
        {
            field: translateHandler('actions'),
            type: 'actions',
            flex: 1,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                    icon={<Restore />}
                    title={translateHandler('restore')}
                    label={translateHandler('restore')}
                    onClick={() => dialogHandler(true, params.id)}
                />,
                <GridActionsCellItem
                    icon={<Delete />}
                    title={translateHandler('permanentlyDelete')}
                    label={translateHandler('permanentlyDelete')}
                    onClick={() => dialogHandler(true, params.id, 'delete')}
                />,
            ],
        },
    ];
}

function getUsersColumns(
    translateHandler: Function,
    navigateHandler: Function,
    dialogHandler: Function,
): GridColDef[] {
    const [
        stringFilterOperators,
        numericFilterOperators,
    ]: GridFilterOperator[][] = getFilterOperators();

    return [
        {
            field: 'id',
            headerName: 'ID',
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'name',
            headerName: translateHandler('name'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'email',
            headerName: translateHandler('email'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'phoneNumber',
            headerName: translateHandler('phoneNumber'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'companyName',
            headerName: translateHandler('companyName'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'companyAddress',
            headerName: translateHandler('companyAddress'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'isActive',
            headerName: translateHandler('isActive'),
            type: 'boolean',
            flex: 1,
        },
        {
            field: 'userNotified',
            headerName: translateHandler('userNotified'),
            type: 'boolean',
            flex: 1,
        },
        {
            field: 'userGroup',
            headerName: translateHandler('userGroup'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: translateHandler('actions'),
            type: 'actions',
            flex: 1,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                    icon={<Delete />}
                    title={translateHandler('delete')}
                    label={translateHandler('delete')}
                    onClick={() => dialogHandler(true, params.id)}
                />,
                <GridActionsCellItem
                    icon={<Edit />}
                    title={translateHandler('edit')}
                    label={translateHandler('edit')}
                    onClick={() => navigateHandler(`/manage-users/edit/${params.id}`)}
                />,
                <GridActionsCellItem
                    icon={<Visibility />}
                    title={translateHandler('viewCompletedCourses')}
                    label={translateHandler('viewCompletedCourses')}
                    onClick={() => navigateHandler(`/manage-users/view/${params.id}`)}
                />,
            ],
        },
    ];
}

function getDeletedUsersColumns(
    translateHandler: Function,
    dialogHandler: Function,
): GridColDef[] {
    const [
        stringFilterOperators,
        numericFilterOperators,
    ]: GridFilterOperator[][] = getFilterOperators();

    return [
        {
            field: 'id',
            headerName: 'ID',
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'name',
            headerName: translateHandler('name'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'email',
            headerName: translateHandler('email'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'phoneNumber',
            headerName: translateHandler('phoneNumber'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'companyName',
            headerName: translateHandler('companyName'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'companyAddress',
            headerName: translateHandler('companyAddress'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'isActive',
            headerName: translateHandler('isActive'),
            type: 'boolean',
            flex: 1,
        },
        {
            field: 'userNotified',
            headerName: translateHandler('userNotified'),
            type: 'boolean',
            flex: 1,
        },
        {
            field: translateHandler('actions'),
            type: 'actions',
            flex: 1,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                    icon={<Restore />}
                    title={translateHandler('restore')}
                    label={translateHandler('restore')}
                    onClick={() => dialogHandler(true, params.id)}
                />,
                <GridActionsCellItem
                    icon={<Delete />}
                    title={translateHandler('permanentlyDelete')}
                    label={translateHandler('permanentlyDelete')}
                    onClick={() => dialogHandler(true, params.id, 'delete')}
                />,
            ],
        },
    ];
}

function getSignupsColumns(
    translateHandler: Function,
    navigateHandler: Function,
): GridColDef[] {
    const [
        stringFilterOperators,
        numericFilterOperators,
    ]: GridFilterOperator[][] = getFilterOperators();

    return [
        {
            field: 'id',
            headerName: 'ID',
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'title',
            headerName: translateHandler('title'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'signups',
            headerName: translateHandler('signups'),
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'signupLimit',
            headerName: translateHandler('signupLimit'),
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'location',
            headerName: translateHandler('location'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'startTime',
            headerName: translateHandler('startTime'),
            valueGetter: (value: string): string | Date => !value ? '' : value,
            valueFormatter: (value: string): string => !value
                ? ''
                : formatTime(value, ','),
            type: 'dateTime',
            flex: 1,
        },
        {
            field: 'isPublic',
            headerName: translateHandler('isPublic'),
            type: 'boolean',
            flex: 1,
        },
        {
            field: 'parentId',
            headerName: translateHandler('parentCourseId'),
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'diplomaFile',
            headerName: translateHandler('diplomaFile'),
            valueGetter: (value: DiplomaFile | null): string => !value ? '' : value.name,
            flex: 1,
        },
        {
            field: translateHandler('actions'),
            type: 'actions',
            flex: 1,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                    icon={<Visibility />}
                    title={translateHandler('view')}
                    label={translateHandler('view')}
                    onClick={() => navigateHandler(`/manage-signups/view/${params.id}`)}
                />,
            ],
        },
    ];
}

function getSignupListColumns(
    translateHandler: Function,
): GridColDef[] {
    const [
        stringFilterOperators,
        numericFilterOperators,
    ]: GridFilterOperator[][] = getFilterOperators();

    return [
        {
            field: 'id',
            headerName: 'ID',
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'name',
            headerName: translateHandler('name'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'email',
            headerName: translateHandler('email'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'phoneNumber',
            headerName: translateHandler('phoneNumber'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'companyName',
            headerName: translateHandler('companyName'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
    ];
}

function getCompletedCoursesColumns(translateHandler: Function): GridColDef[] {
    const [
        stringFilterOperators,
        numericFilterOperators,
    ]: GridFilterOperator[][] = getFilterOperators();

    return [
        {
            field: 'id',
            headerName: 'ID',
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'title',
            headerName: translateHandler('title'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'description',
            headerName: translateHandler('description'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'targetAudience',
            headerName: translateHandler('targetAudience'),
            valueGetter: (value: string) => translateHandler(value),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'duration',
            headerName: translateHandler('duration'),
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'durationType',
            headerName: translateHandler('durationType'),
            valueGetter: (value: string) => translateHandler(value),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'hasPrice',
            headerName: translateHandler('hasPrice'),
            type: 'boolean',
            flex: 1,
        },
        {
            field: 'price',
            headerName: translateHandler('price'),
            valueGetter: (value: number) => !value ? '' : value,
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'format',
            headerName: translateHandler('format'),
            valueGetter: (value: string) => translateHandler(value),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'signupLimit',
            headerName: translateHandler('signupLimit'),
            valueGetter: (value: number) => !value ? '' : value,
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'location',
            headerName: translateHandler('location'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'courseType',
            headerName: translateHandler('courseType'),
            valueGetter: (value: string) => translateHandler(value),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'startTime',
            headerName: translateHandler('startTime'),
            valueGetter: (value: string): string | Date => !value ? '' : value,
            valueFormatter: (value: string): string => !value
                ? ''
                : formatTime(value, ','),
            type: 'dateTime',
            flex: 1,
        },
        {
            field: 'richTextDescription',
            headerName: translateHandler('richTextDescription'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'courseCategory',
            headerName: translateHandler('courseCategory'),
            valueGetter: (value: string) => translateHandler(value),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'isPublic',
            headerName: translateHandler('isPublic'),
            type: 'boolean',
            flex: 1,
        },
        {
            field: 'assignedTo',
            headerName: translateHandler('assignedTo'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'linkedId',
            headerName: translateHandler('linkedCourseId'),
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'assignedToGroups',
            headerName: translateHandler('assignedToGroups'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'parentId',
            headerName: translateHandler('parentCourseId'),
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: 'diplomaFile',
            headerName: translateHandler('diplomaFile'),
            valueGetter: (value: DiplomaFile | null): string => !value ? '' : value.name,
            flex: 1,
        },
        {
            field: 'createdAt',
            headerName: translateHandler('createdAt'),
            valueGetter: (value: string): string | Date => !value ? '' : value,
            valueFormatter: (value: string): string => !value
                ? ''
                : formatTime(value, ','),
            type: 'dateTime',
            flex: 1,
        },
    ];
}

function getUserGroupsColumns(
    translateHandler: Function,
    navigateHandler: Function,
    dialogHandler: Function,
): GridColDef[] {
    const [
        stringFilterOperators,
        numericFilterOperators,
    ]: GridFilterOperator[][] = getFilterOperators();

    return [
        {
            field: 'groupKey',
            headerName: translateHandler('groupKey'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'groupName',
            headerName: translateHandler('groupName'),
            filterOperators: stringFilterOperators,
            flex: 1,
        },
        {
            field: 'userAmount',
            headerName: translateHandler('userAmount'),
            type: 'number',
            filterOperators: numericFilterOperators,
            flex: 1,
        },
        {
            field: translateHandler('actions'),
            type: 'actions',
            flex: 1,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                    icon={<Delete />}
                    title={translateHandler('delete')}
                    label={translateHandler('delete')}
                    onClick={() => dialogHandler(true, params.id)}
                />,
                <GridActionsCellItem
                    icon={<Edit />}
                    title={translateHandler('edit')}
                    label={translateHandler('edit')}
                    onClick={() => navigateHandler(`/manage-user-groups/edit/${params.id}`)}
                />,
            ],
        },
    ];
}

function getFilterOperators(): GridFilterOperator[][] {
    const disabledOperators: Array<string> = ['isEmpty', 'isNotEmpty', 'isAnyOf'];
    const stringFilterOperators: GridFilterOperator[] = getGridStringOperators().filter(({ value }: { value: string }) =>
        !disabledOperators.includes(value)
    );
    const numericFilterOperators: GridFilterOperator[] = getGridNumericOperators().filter(({ value }: { value: string }) =>
        !disabledOperators.includes(value)
    );
    return [stringFilterOperators, numericFilterOperators];
}

export {
    getCoursesColumns,
    getDeletedCoursesColumns,
    getUsersColumns,
    getDeletedUsersColumns,
    getSignupsColumns,
    getSignupListColumns,
    getCompletedCoursesColumns,
    getUserGroupsColumns,
};
