import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FormControl,
    Select,
    SelectChangeEvent,
    MenuItem,
    menuClasses,
} from '@mui/material';

const LanguageSwitcher = memo(function LanguageSwitcher(): React.ReactElement {
    const { i18n } = useTranslation();

    const changeHandler = useCallback((event: SelectChangeEvent): void => {
        const { value } = event.target;
        if (i18n.language !== value) {
            localStorage.setItem('preferred-language', value);
            i18n.changeLanguage(value);
        }
    }, [i18n]);

    return (
        <FormControl className="language-switcher">
            <Select
                labelId="locale-select-label"
                id="locale-select"
                value={i18n.language}
                onChange={changeHandler}
                label="Language"
                variant="filled"
                IconComponent={() => null}
                renderValue={(selected) => (
                    <div className="selected-option">{selected === 'en' ? 'EN' : 'NO'}</div>
                )}
                MenuProps={{
                    sx: {
                        [`& .${menuClasses.paper}`]: {
                            background: 'transparent',
                        },
                        [`& .${menuClasses.list}`]: {
                            paddingTop: 0,
                            paddingBottom: 0,
                            background: 'var(--text-color)',
                            borderRadius: '4px',
                            "& li": {
                                padding: '4px 8px',
                                transition: 'background 0.05s ease-in-out',
                            },
                            "& li.Mui-selected": {
                                background: 'var(--button-color)',
                                color: 'var(--text-color)',
                            },
                        },
                    },
                }}
            >
                <MenuItem value="en" className="language-switcher-item">
                    <div className="language-select-option">EN</div>
                </MenuItem>
                <MenuItem value="no" className="language-switcher-item">
                    <div className="language-select-option">NO</div>
                </MenuItem>
            </Select>
        </FormControl>
    );
});

export default LanguageSwitcher;
