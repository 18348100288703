import React, { memo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import {
    useGetUserGroups,
    deleteUserGroup,
    deleteUserGroups,
} from '../../api/Admin/Utils';
import {
    Backdrop,
    CircularProgress,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    LinearProgress,
    Theme,
    TextField,
} from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridFilterModel,
    GridRowId,
    GridRowSelectionModel,
    GridSlots,
} from '@mui/x-data-grid';
import Toolbar from './Toolbar';
import { getUserGroupsColumns } from '../../helpers/Admin/Columns';
import { getGridLocalization } from '../../helpers/Admin/GridLocalization';
import { toast } from 'react-toastify';
import { UserGroup } from '../../../../server/types/admin/core';
import { AxiosError } from 'axios';

const ManageUserGroups = memo(function ManageUserGroups(): React.ReactElement {
    const { t } = useTranslation();
    const navigate = useNavigate({ from: '/manage-user-groups' });
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [singleDeleteDialogOpen, setSingleDeleteDialogOpen] = useState<boolean>(false);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [loading, setLoading] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string | null>(null);
    const [userAmount, setUserAmount] = useState<number>(0);
    const [deleteInput, setDeleteInput] = useState<string>('');
    const { data: userGroups, isLoading, error, refetch } = useGetUserGroups();

    if (error) {
        const { responseText } = error.request;
        const errorMessage: string = responseText ? responseText : error.message;
        toast.error(t(errorMessage), {
            hideProgressBar: true,
        });
    }

    const translateHandler = useCallback((text: string): string => {
        return t(text);
    }, [t]);

    const singleDialogHandler = useCallback((state: boolean, id?: GridRowId) => {
        if (state) {
            const userGroupToDelete: UserGroup = userGroups!.find((userGroup: UserGroup): boolean => userGroup.groupKey === id)!;
            setUserAmount(userGroupToDelete.userAmount!);
            setDeleteId(id as string);
            setSingleDeleteDialogOpen(state);
            return;
        }

        setSingleDeleteDialogOpen(state);
        setDeleteId(null);
    }, [userGroups]);

    const dialogHandler = useCallback((state: boolean) => {
        if (state) {
            const userGroupsToDelete: UserGroup[] = userGroups!.filter((userGroup: UserGroup): boolean => rowSelectionModel.includes(userGroup.groupKey));
            const amount: number = userGroupsToDelete.reduce((total: number, { userAmount }: UserGroup) => {
                total += userAmount!;
                return total;
            }, 0);
            setUserAmount(amount);
            setDeleteDialogOpen(state);
            return;
        }

        setDeleteDialogOpen(state);
    }, [userGroups, rowSelectionModel]);

    const navigateHandler = useCallback((path: string): void => {
        navigate({ to: path });
    }, [navigate]);

    const columns: GridColDef[] = getUserGroupsColumns(
        translateHandler,
        navigateHandler,
        singleDialogHandler,
    );

    const deleteHandler = useCallback((): void => {
        setDeleteInput('');
        dialogHandler(false);
        setLoading(true);
        deleteUserGroups(rowSelectionModel)
            .then((message: string): void => {
                toast.success(t(message), {
                    hideProgressBar: true,
                });
                refetch();
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
            })
            .finally((): void => setLoading(false));
    }, [dialogHandler, rowSelectionModel, t, refetch]);

    const singleDeleteHandler = useCallback((): void => {
        setDeleteInput('');
        singleDialogHandler(false);
        setLoading(true);
        deleteUserGroup(deleteId!)
            .then((message: string): void => {
                toast.success(t(message), {
                    hideProgressBar: true,
                });
                refetch();
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
            })
            .finally((): void => setLoading(false));
    }, [singleDialogHandler, deleteId, t, refetch]);

    return (
        <div className="admin-page-container manage-user-groups-container">
            <Backdrop open={loading}
                      sx={{ zIndex: (theme: Theme) => theme.zIndex.drawer + 1 }}
            >
                <CircularProgress sx={{ color: 'var(--background-color)' }} />
            </Backdrop>
            <div className="admin-page-wrapper manage-user-groups-wrapper">
                <div className="admin-page-title manage-user-groups-title">{t('manageUserGroups')}</div>
                <div className="admin-page-content manage-user-groups-content">
                    <div className="admin-page-controls manage-user-groups-controls">
                        { rowSelectionModel.length > 0 &&
                            <Button variant="contained"
                                    className="action-button"
                                    onClick={() => dialogHandler(true)}
                            >{t('deleteSelected')}</Button>
                        }
                        <Dialog
                            open={singleDeleteDialogOpen}
                            onClose={() => singleDialogHandler(false)}
                            aria-labelledby="single-delete-dialog-title"
                        >
                            <DialogTitle id="single-delete-dialog-title">{t('deleteUserGroup?')}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="single-delete-dialog-description">
                                    {t('userGroupPermanentDeletionNote')}
                                </DialogContentText>
                                {userAmount > 0 &&
                                    <>
                                        <DialogContentText
                                            id="single-delete-dialog-type-delete"
                                            sx={{
                                                marginTop: '12px',
                                            }}
                                        >
                                            {t(`userGroupTypeSingleDelete.${userAmount > 1 ? 'multiple' : 'single'}`, { amount: userAmount })}
                                        </DialogContentText>
                                        <TextField
                                            id="single-delete-input"
                                            value={deleteInput}
                                            onChange={(e) => setDeleteInput(e.target.value)}
                                            sx={{
                                                background: '#fff',
                                                position: 'relative',
                                                marginTop: '12px',
                                            }}
                                        />
                                    </>
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    className="action-button"
                                    onClick={() => singleDialogHandler(false)}>{t('cancel')}</Button>
                                <Button
                                    variant="contained"
                                    className="action-button"
                                    onClick={singleDeleteHandler}
                                    autoFocus
                                    disabled={userAmount > 0 && deleteInput !== 'delete'}>{t('delete')}</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={deleteDialogOpen}
                            onClose={() => dialogHandler(false)}
                            aria-labelledby="delete-dialog-title"
                        >
                            <DialogTitle id="delete-dialog-title">{t('deleteSelectedUserGroups?')}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="delete-dialog-description">
                                    {t('userGroupPermanentDeletionNote')}
                                </DialogContentText>
                                {userAmount > 0 &&
                                    <>
                                        <DialogContentText
                                            id="delete-dialog-type-delete"
                                            sx={{
                                                marginTop: '12px',
                                            }}
                                        >
                                            {t(`userGroupTypeDelete.${userAmount > 1 ? 'multiple' : 'single'}`, { amount: userAmount })}
                                        </DialogContentText>
                                        <TextField
                                            id="delete-input"
                                            value={deleteInput}
                                            onChange={(e) => setDeleteInput(e.target.value)}
                                            sx={{
                                                background: '#fff',
                                                position: 'relative',
                                                marginTop: '12px',
                                            }}
                                        />
                                    </>
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    className="action-button"
                                    onClick={() => dialogHandler(false)}>{t('cancel')}</Button>
                                <Button
                                    variant="contained"
                                    className="action-button"
                                    onClick={deleteHandler}
                                    autoFocus
                                    disabled={userAmount > 0 && deleteInput !== 'delete'}>{t('delete')}</Button>
                            </DialogActions>
                        </Dialog>
                        <Button variant="contained"
                                className="action-button"
                                onClick={() => navigateHandler('/manage-user-groups/new')}
                        >{t('create')}</Button>
                    </div>
                    <div className="admin-page-grid manage-user-groups-grid">
                        <DataGrid
                            loading={isLoading}
                            columns={columns}
                            rows={userGroups ?? []}
                            getRowId={(row: UserGroup) => row.groupKey}
                            slots={{
                                toolbar: Toolbar,
                                loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
                            }}
                            sx={{
                                color: 'var(--background-color)',
                                border: 'none',
                                '& .MuiDataGrid-toolbarContainer': {
                                    background: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    color: 'var(--background-color)',
                                    padding: '10px',
                                },
                                '& .MuiDataGrid-topContainer::after': {
                                    background: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-main > *:first-of-type': {
                                    borderRadius: 0,
                                },
                                '& .MuiDataGrid-topContainer': {
                                    borderBottom: '1px solid var(--background-color)',
                                },
                                '& .MuiDataGrid-columnHeaders [role="row"]': {
                                    background: 'var(--text-color)',
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '& .MuiDataGrid-columnSeparator:hover': {
                                    color: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-withBorderColor': {
                                    borderColor: 'var(--background-color)',
                                },
                                '& .MuiCheckbox-root': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiCheckbox-root.Mui-checked': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-virtualScrollerContent .MuiCheckbox-root': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-virtualScrollerContent .MuiCheckbox-root.Mui-checked': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-overlayWrapperInner > div': {
                                    background: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-filler': {
                                    display: 'none',
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    background: 'var(--text-color)',
                                    borderColor: 'var(--background-color)',
                                },
                                '& .MuiTablePagination-toolbar': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiTablePagination-selectIcon': {
                                    color: 'var(--background-color)',
                                },
                            }}
                            slotProps={{
                                loadingOverlay: {
                                    sx: {
                                        backgroundColor: 'var(--text-color)',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: 'var(--button-color)',
                                        },
                                    },
                                },
                                pagination: {
                                    labelRowsPerPage: t('rowsPerPage'),
                                },
                            }}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 20 } },
                            }}
                            localeText={getGridLocalization(translateHandler, 'noUserGroups')}
                            pageSizeOptions={[20, 50, 100]}
                            onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel): void => {
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                            rowSelectionModel={rowSelectionModel}
                            checkboxSelection={true}
                            onFilterModelChange={(newFilterModel: GridFilterModel): void => {
                                setFilterModel(newFilterModel);
                            }}
                            filterModel={filterModel}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ManageUserGroups;
