import React, { memo, useContext, useState, useCallback } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import CourseFilters from './CourseFilters';
import CourseList from './CourseList';
import { useSearch, useNavigate } from '@tanstack/react-router';
import { toast } from 'react-toastify';
import type { Tag } from '../../../server/types/core';

const Courses = memo(function Courses(): React.ReactElement {
    const { token, message }: { token: string, message: string } = useSearch({ strict: false });
    const { user, updateLoginState } = useContext(AuthContext);
    const { t } = useTranslation();
    const navigate = useNavigate({ from: '/courses' });
    const [activeTag, setActiveTag] = useState<Tag>('courses');

    if (token) {
        localStorage.setItem('auth-token', token);
        updateLoginState();
        navigate({ to: '/courses' });
    }

    if (message) {
        toast.success(t(message), {
            hideProgressBar: true,
        });
        navigate({ to: '/courses' });
    }

    const myCoursesTagHandler = useCallback((): void => {
        if (!user) {
            navigate({
                to: '/login',
                search: {
                    redirect: '/courses',
                },
            });
            return;
        }

        setActiveTag('my_courses');
    }, [user, navigate]);

    return (
        <div className="courses-container">
            <div className="tag-container">
                <div
                    className={`tag${activeTag === 'courses' ? ' active' : ''}`}
                    onClick={() => setActiveTag('courses')}
                >{t('courses')}</div>
                <div
                    className={`tag${activeTag === 'my_courses' ? ' active' : !user ? ' disabled' : ''}`}
                    onClick={() => myCoursesTagHandler()}
                >{t('myCourses')}</div>
            </div>
            <CourseFilters activeTag={activeTag}>
                <CourseList activeTag={activeTag} />
            </CourseFilters>
        </div>
    );
});

export default Courses;
