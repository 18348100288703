import React, { memo, useState, useEffect, useMemo, useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Link } from '@tanstack/react-router';
import {
    Backdrop,
    CircularProgress,
    Theme,
    TextField,
    FormControl,
    OutlinedInput,
    MenuItem,
    Box,
    Chip,
    FormHelperText,
    Button,
    FormControlLabel,
    Switch,
    Autocomplete,
    AutocompleteRenderInputParams,
    SelectChangeEvent,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import {
    useGetCourseSelectOptions,
    getCourse,
    createOrUpdateCourse,
} from '../../api/Admin/Utils';
import { toast } from 'react-toastify';
import { defaultCourse } from '../../helpers/Admin/DefaultValues';
import type { UserGroup, CourseHierarchy, DiplomaFile } from '../../../../server/types/admin/core';
import type {
    Course,
    Format,
    CourseCategory,
    DurationType,
    CourseType,
    AssignedTo,
    LinkedId,
    ParentId,
} from '../../../../server/types/core';
import { AxiosError } from 'axios';
import '../../styles/Admin/Course.css';

// TODO: Break this component into smaller components (500 lines or less)
// TODO: Split states between course formats
const AdminCourse = memo(function AdminCourse(): React.ReactElement {
    const { courseId }: { courseId: string } = useParams({ strict: false });
    const urlPath = window.location.pathname;
    const { t } = useTranslation();
    const navigate = useNavigate({
        from: urlPath.includes('edit')
            ? urlPath.replace(courseId, '$courseId')
            : urlPath
    });
    const [course, setCourse] = useState<Course | null>(null);
    const [title, setTitle] = useState<string>(defaultCourse.title);
    const [description, setDescription] = useState<string>(defaultCourse.description);
    const [targetAudience, setTargetAudience] = useState<string[]>(defaultCourse.targetAudience);
    const [duration, setDuration] = useState<number>(defaultCourse.duration);
    const [durationType, setDurationType] = useState<DurationType>(defaultCourse.durationType);
    const [hasPrice, setHasPrice] = useState<boolean>(defaultCourse.hasPrice);
    const [price, setPrice] = useState<number>(defaultCourse.price);
    const [format, setFormat] = useState<Format>(defaultCourse.format);
    const [signupLimit, setSignupLimit] = useState<number>(defaultCourse.signupLimit);
    const [location, setLocation] = useState<string>(defaultCourse.location);
    const [courseType, setCourseType] = useState<CourseType>(defaultCourse.courseType);
    const [startTime, setStartTime] = useState<Dayjs | null>(defaultCourse.startTime);
    const [thumbnail, setThumbnail] = useState<File | string>(defaultCourse.thumbnail);
    const [thumbnailSrc, setThumbnailSrc] = useState<string>(defaultCourse.thumbnailSrc);
    const [richTextDescription, setRichTextDescription] = useState<string>(defaultCourse.richTextDescription);
    const [scormFile, setScormFile] = useState<File | string>(defaultCourse.scormFile);
    const [courseCategory, setCourseCategory] = useState<CourseCategory>(defaultCourse.courseCategory);
    const [isPublic, setIsPublic] = useState<boolean>(defaultCourse.isPublic);
    const [assignedTo, setAssignedTo] = useState<AssignedTo[]>(defaultCourse.assignedTo);
    const [linkedId, setLinkedId] = useState<LinkedId | null>(defaultCourse.linkedId);
    const [assignedToGroups, setAssignedToGroups] = useState<UserGroup[]>(defaultCourse.assignedToGroups);
    const [courseHierarchy, setCourseHierarchy] = useState<CourseHierarchy>(defaultCourse.courseHierarchy);
    const [parentId, setParentId] = useState<ParentId | null>(defaultCourse.parentId);
    const [diplomaFile, setDiplomaFile] = useState<DiplomaFile | null>(defaultCourse.diplomaFile);
    const [invalidFields, setInvalidFields] = useState<{ [key: string]: boolean }>({
        title: false,
        description: false,
        duration: false,
        price: false,
        signupLimit: false,
        location: false,
        startTime: false,
        thumbnail: false,
        scormFile: false,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const {
        data: courseSelectOptions,
        isLoading,
        error,
    } = useGetCourseSelectOptions(courseId ? parseInt(courseId) : null);
    const reader: FileReader = useMemo(() => new FileReader(), []);

    if (error) {
        const { responseText } = error.request;
        const errorMessage: string = responseText ? responseText : error.message;
        toast.error(t(errorMessage), {
            hideProgressBar: true,
        });
        navigate({ to: '/manage-courses' });
    }

    const updateCourseFormData = useCallback((
        {
            courseData,
            courseParentId,
        }: {
            courseData: Course,
            courseParentId: ParentId | null,
        }
    ): void => {
        setTitle(courseData.title);
        setDescription(courseData.description);
        setTargetAudience(courseData.targetAudience as string[]);
        setDuration(courseData.duration);
        setDurationType(courseData.durationType);
        setHasPrice(courseData.hasPrice as boolean);
        setPrice(courseData.price);
        setFormat(courseData.format);
        setSignupLimit(courseData.signupLimit);
        setLocation(!courseData.location
            ? ''
            : courseData.location
        );
        setCourseType(courseData.courseType);
        setStartTime(!courseData.startTime ? null : dayjs(courseData.startTime));
        setThumbnail(courseData.thumbnail);
        setThumbnailSrc(courseData.thumbnail as string);
        setRichTextDescription(courseData.richTextDescription!);
        setScormFile(courseData.scormFile);
        setCourseCategory(courseData.courseCategory);
        setIsPublic(courseData.isPublic! as boolean);
        setAssignedTo(courseData.assignedTo as AssignedTo[]);
        setLinkedId(courseData.linkedId as LinkedId | null);
        setAssignedToGroups(courseData.assignedToGroups as UserGroup[]);
        setCourseHierarchy(courseParentId ? 'child' : 'parent');
        setParentId(courseParentId);
        setDiplomaFile(courseData.diplomaFile as DiplomaFile | null);
    }, []);

    const resetCourseData = useCallback((): void => {
        if (!courseId) {
            updateCourseFormData({
                courseData: defaultCourse,
                courseParentId: null,
            });
            return;
        }

        updateCourseFormData({
            courseData: course!,
            courseParentId: null,
        });
    }, [courseId, updateCourseFormData, course]);

    const updateCourseData = useCallback(async (courseParentId?: ParentId): Promise<void> => {
        if (courseParentId) {
            setLoading(true);
            const parentCourseData: Course = await getCourse(courseParentId.id);
            updateCourseFormData({
                courseData: parentCourseData,
                courseParentId,
            });
            setLoading(false);
            return;
        }

        try {
            setLoading(true);
            const courseData: Course = await getCourse(parseInt(courseId));
            setCourse(courseData);
            updateCourseFormData({
                courseData,
                courseParentId: courseData.parentId as ParentId | null,
            });
            setLoading(false);
        } catch (e: any) {
            const { responseText } = e.request;
            const errorMessage: string = responseText ? responseText : e.message;
            toast.error(t(errorMessage), {
                hideProgressBar: true,
            });
            navigate({ to: '/manage-courses' });
        }
    }, [courseId, updateCourseFormData, t, navigate]);

    useEffect((): void => {
        if (courseId) {
            updateCourseData();
        }
    }, [courseId, updateCourseData]);

    reader.onload = useCallback((): void => {
        setThumbnailSrc(reader.result as string);
    }, [reader.result]);

    const validateFields = useCallback((): boolean => {
        const titleInvalid: boolean = title === '';
        const descriptionInvalid: boolean = description === '';
        const durationInvalid: boolean = duration < 1;
        const priceInvalid: boolean = hasPrice && price < 1;
        const signupLimitInvalid: boolean = format === 'physical' && signupLimit < 1;
        const locationInvalid: boolean = format === 'physical' && location === '';
        const startTimeInvalid: boolean = format === 'physical' && startTime === null;
        const thumbnailInvalid: boolean = thumbnail === '';
        const scormFileInvalid: boolean = format === 'digital' && scormFile === '';

        setInvalidFields({
            title: titleInvalid,
            description: descriptionInvalid,
            duration: durationInvalid,
            price: priceInvalid,
            signupLimit: signupLimitInvalid,
            location: locationInvalid,
            startTime: startTimeInvalid,
            thumbnail: thumbnailInvalid,
            scormFile: scormFileInvalid,
        });

        return titleInvalid
            || descriptionInvalid
            || durationInvalid
            || priceInvalid
            || signupLimitInvalid
            || locationInvalid
            || startTimeInvalid
            || thumbnailInvalid
            || scormFileInvalid;
    }, [
        title,
        description,
        duration,
        hasPrice,
        price,
        format,
        signupLimit,
        location,
        startTime,
        thumbnail,
        scormFile,
    ]);

    const hasChanged = useCallback((): boolean => {
        const oldIds: string = (course!.assignedTo! as AssignedTo[]).map((assigned: AssignedTo) => assigned.id).join(',');
        const newIds: string = assignedTo.map((assigned: AssignedTo) => assigned.id).join(',');
        const startTimeComparison: boolean = !startTime
            ? startTime !== course!.startTime
            : startTime?.format('YYYY-MM-DD HH:mm:ss') !== course!.startTime;
        const oldLinkedId: number | null = (course!.linkedId as LinkedId | null)?.id ?? null;
        const newLinkedId: number | null = linkedId?.id ?? null;
        const oldKeys: string = (course!.assignedToGroups! as UserGroup[]).map((group: UserGroup) => group.groupKey).join(',');
        const newKeys: string = assignedToGroups.map((group: UserGroup) => group.groupKey).join(',');
        const oldParentId: number | null = (course!.parentId as ParentId | null)?.id ?? null;
        const newParentId: number | null = parentId?.id ?? null;
        const oldDiplomaFile: string | null = (course!.diplomaFile as DiplomaFile | null)?.name ?? null;
        const newDiplomaFile: string | null = diplomaFile?.name ?? null;
        return title !== course!.title
            || description !== course!.description
            || targetAudience.join(',') !== (course!.targetAudience as string[]).join(',')
            || duration !== course!.duration
            || durationType !== course!.durationType
            || hasPrice !== course!.hasPrice
            || price !== course!.price
            || format !== course!.format
            || signupLimit !== course!.signupLimit
            || location !== course!.location
            || courseType !== course!.courseType
            || startTimeComparison
            || thumbnail !== course!.thumbnail
            || richTextDescription !== course!.richTextDescription
            || scormFile !== course!.scormFile
            || courseCategory !== course!.courseCategory
            || isPublic !== course!.isPublic
            || newIds !== oldIds
            || newLinkedId !== oldLinkedId
            || newKeys !== oldKeys
            || newParentId !== oldParentId
            || newDiplomaFile !== oldDiplomaFile;
    }, [
        course,
        assignedTo,
        title,
        description,
        targetAudience,
        duration,
        durationType,
        hasPrice,
        price,
        format,
        signupLimit,
        location,
        courseType,
        startTime,
        thumbnail,
        richTextDescription,
        scormFile,
        courseCategory,
        isPublic,
        linkedId,
        assignedToGroups,
        parentId,
        diplomaFile,
    ]);

    const submitHandler = useCallback((): void => {
        if (validateFields()) {
            toast.error(t('pleaseFillOutAllFields'), {
                hideProgressBar: true,
            });
            return;
        }

        if (courseId && !hasChanged()) {
            navigate({ to: '/manage-courses' });
            return;
        }

        setLoading(true);
        const courseToSave: Course = {
            id: parseInt(courseId),
            title,
            description,
            targetAudience,
            duration,
            durationType,
            hasPrice,
            price,
            format,
            signupLimit: format === 'digital'
                ? 0
                : signupLimit,
            location: format === 'digital'
                ? ''
                : location,
            courseType,
            startTime: format === 'digital'
                ? null
                : startTime!.format('YYYY-MM-DD HH:mm:ss'),
            thumbnail,
            richTextDescription,
            scormFile,
            courseCategory,
            isPublic,
            assignedTo,
            linkedId: linkedId?.id ?? null,
            assignedToGroups,
            parentId: parentId?.id ?? null,
            diplomaFile,
        };

        if (course?.thumbnail && thumbnail instanceof File) {
            courseToSave.prevThumbnail = course.thumbnail as string;
        }

        if (course?.scormFile && scormFile instanceof File) {
            courseToSave.prevScormFile = course.scormFile as string;
        }

        createOrUpdateCourse(courseToSave)
            .then((message: string): void => {
                toast.success(t(message), {
                    hideProgressBar: true,
                });
                navigate({ to: '/manage-courses' });
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
                if (courseId) {
                    resetCourseData();
                }
            })
            .finally(() => setLoading(false));
    }, [
        validateFields,
        courseId,
        hasChanged,
        navigate,
        title,
        description,
        targetAudience,
        duration,
        durationType,
        hasPrice,
        price,
        format,
        signupLimit,
        location,
        courseType,
        startTime,
        thumbnail,
        richTextDescription,
        scormFile,
        courseCategory,
        isPublic,
        assignedTo,
        linkedId?.id,
        assignedToGroups,
        parentId?.id,
        diplomaFile,
        course?.thumbnail,
        course?.scormFile,
        t,
        resetCourseData,
    ]);

    const keyUpHandler = useCallback((event: React.KeyboardEvent): void => {
        if (event.key === 'Enter') {
            submitHandler();
        }
    }, [submitHandler]);

    const targetAudienceChangeHandler = useCallback(({ target }: SelectChangeEvent<unknown>): void => {
        const targetValue: string | string[] = target.value as string | string[];
        setTargetAudience(typeof targetValue === 'string' ? targetValue.split(',') : targetValue);
    }, []);

    const targetAudienceRenderValueHandler = useCallback((selected: unknown) => {
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {(selected as string[]).map((value: string) => (
                    <Chip key={value} label={value} />
                ))}
            </Box>
        );
    }, []);

    const targetAudienceStylesHandler = useCallback((name: string): { fontFamily: string } => {
        return {
            fontFamily: targetAudience.includes(name)
                ? '"Exo SemiBold", Roboto, Helvetica, Arial, sans-serif !important'
                : '"Exo Regular", Roboto, Helvetica, Arial, sans-serif !important',
        };
    }, [targetAudience]);

    const durationChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
        const parsedValue: number = parseFloat(event.target.value);
        if (Number.isFinite(parsedValue) && parsedValue > 0) {
            setDuration(parsedValue);
        }
    }, []);

    const priceChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
        const parsedValue: number = parseInt(event.target.value);
        if (Number.isInteger(parsedValue) && parsedValue > 0) {
            setPrice(parsedValue);
        }
    }, []);

    const signupLimitChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
        const parsedValue: number = parseInt(event.target.value);
        if (Number.isInteger(parsedValue) && parsedValue > 0) {
            setSignupLimit(parsedValue);
        }
    }, []);

    const thumbnailChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
        const image = event.target.files![0];

        if (!image) {
            return;
        }

        if (image.type.includes('image/')) {
            setThumbnail(image);
            reader.readAsDataURL(image);
        }
    }, [reader]);

    const scormFileChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
        const file = event.target.files![0];

        if (!file) {
            return;
        }

        if (file.type.includes('zip')) {
            setScormFile(file);
        }
    }, []);

    const assignedToOptionEqualToValueHandler = useCallback((option: AssignedTo, value: AssignedTo): boolean => {
        return option.id === value.id;
    }, []);

    const assignedToOptionLabelHandler = useCallback((option: AssignedTo) => option.email, []);

    const assignedToRenderInputHandler = useCallback((params: AutocompleteRenderInputParams) => {
        return (<TextField
            {...params}
            label={t('assignedTo')}
        />);
    }, [t]);

    const linkedIdOptionEqualToValueHandler = useCallback((option: LinkedId, value: LinkedId): boolean => {
        return option.id === value.id;
    }, []);

    const linkedIdOptionLabelHandler = useCallback((option: LinkedId) => option.title, []);

    const linkedIdRenderInputHandler = useCallback((params: AutocompleteRenderInputParams) => {
        return (<TextField
            {...params}
            label={t('linkedCourse')}
        />);
    }, [t]);

    const assignedToGroupsOptionEqualToValueHandler = useCallback((option: UserGroup, value: UserGroup): boolean => {
        return option.groupKey === value.groupKey;
    }, []);

    const assignedToGroupsOptionLabelHandler = useCallback((option: UserGroup) => option.groupName, []);

    const assignedToGroupsRenderInputHandler = useCallback((params: AutocompleteRenderInputParams) => {
        return (<TextField
            {...params}
            label={t('assignedToGroups')}
        />);
    }, [t]);

    const courseHierarchyChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value === 'child') {
            if (courseSelectOptions!.parentIdOptions.length > 0) {
                updateCourseData(courseSelectOptions?.parentIdOptions[0]);
                return;
            }

            toast('noParentCourses');
        }

        resetCourseData();
    }, [courseSelectOptions, updateCourseData, resetCourseData]);

    const parentIdOptionEqualToValueHandler = useCallback((option: ParentId, value: ParentId): boolean => {
        return option.id === value.id;
    }, []);

    const parentIdOptionLabelHandler = useCallback((option: ParentId) => option.title, []);

    const parentIdChangeHandler = useCallback((event: any, newValue: ParentId | null): void => {
        if (!newValue) {
            resetCourseData();
            return;
        }

        updateCourseData(newValue);
    }, [resetCourseData, updateCourseData]);

    const parentIdRenderInputHandler = useCallback((params: AutocompleteRenderInputParams) => {
        return (<TextField
            {...params}
            label={t('parentCourse')}
        />);
    }, [t]);

    const diplomaFileOptionEqualToValueHandler = useCallback((option: DiplomaFile, value: DiplomaFile): boolean => {
        return option.url === value.url;
    }, []);

    const diplomaFileOptionKeyHandler = useCallback((option: DiplomaFile) => option.url, []);

    const diplomaFileOptionLabelHandler = useCallback((option: DiplomaFile) => option.name, []);

    const diplomaFileRenderInputHandler = useCallback((params: AutocompleteRenderInputParams) => {
        return (<TextField
            {...params}
            label={t('diplomaFile')}
        />);
    }, [t]);

    return (
        <div className="admin-page-container course-container">
            <Backdrop open={loading}
                      sx={{ zIndex: (theme: Theme) => theme.zIndex.drawer + 1 }}
            >
                <CircularProgress sx={{ color: 'var(--background-color)' }} />
            </Backdrop>
            <div className="admin-page-wrapper course-wrapper">
                <Link to="/manage-courses" className="back-link">
                    <BackIcon />
                </Link>
                <div className="admin-page-title course-title">{courseId ? t('editCourse') : t('newCourse')}</div>
                <div className="admin-page-content course-content">
                    <div className="row">
                        <TextField
                            id="format-select"
                            label={t('format')}
                            value={format}
                            onChange={(e) => setFormat((e.target.value) as Format)}
                            sx={{background: '#fff', position: 'relative'}}
                            select
                        >
                            <MenuItem key="digital" value="digital">
                                {t('digital')}
                            </MenuItem>
                            <MenuItem key="physical" value="physical">
                                {t('physicalClassroom')}
                            </MenuItem>
                        </TextField>
                    </div>
                    {format === 'physical' &&
                        <>
                            <div className="row">
                                <TextField
                                    id="course-hierarchy-select"
                                    label={t('courseHierarchy')}
                                    value={courseHierarchy}
                                    onChange={courseHierarchyChangeHandler}
                                    sx={{background: '#fff', position: 'relative'}}
                                    select
                                >
                                    <MenuItem key="parent" value="parent">
                                        {t('parentCourse')}
                                    </MenuItem>
                                    <MenuItem key="child" value="child">
                                        {t('childCourse')}
                                    </MenuItem>
                                </TextField>
                            </div>
                            {courseHierarchy === 'child' &&
                                <div className="row">
                                    <Autocomplete
                                        disablePortal
                                        id="parent-id-select"
                                        options={courseSelectOptions?.parentIdOptions ?? []}
                                        isOptionEqualToValue={parentIdOptionEqualToValueHandler}
                                        getOptionLabel={parentIdOptionLabelHandler}
                                        value={parentId}
                                        loading={isLoading}
                                        disableClearable={courseHierarchy === 'child'}
                                        onChange={parentIdChangeHandler}
                                        renderInput={parentIdRenderInputHandler}
                                        sx={{background: '#fff', position: 'relative'}}
                                    />
                                </div>
                            }
                            <div className="row">
                                <FormControl>
                                    <TextField
                                        id="signup-limit-input"
                                        label={t('signupLimit')}
                                        value={signupLimit}
                                        type="number"
                                        placeholder={t('signupLimit')}
                                        onChange={signupLimitChangeHandler}
                                        inputProps={{min: 1}}
                                        error={invalidFields.signupLimit}
                                        helperText={invalidFields.signupLimit ? t('signupLimitInvalid') : ''}
                                        sx={{background: '#fff', position: 'relative'}}
                                        onKeyUp={keyUpHandler}
                                    />
                                    <FormHelperText
                                        className="signup-limit-helper-text helper-text"
                                    >
                                        {t('maxAmountOfSignups')}
                                    </FormHelperText>
                                </FormControl>
                            </div>
                            <div className="row">
                                <TextField
                                    id="location-input"
                                    label={t('location')}
                                    value={location}
                                    placeholder={t('location')}
                                    onChange={(e) => setLocation(e.target.value)}
                                    error={invalidFields.location}
                                    helperText={invalidFields.location ? t('locationInvalid') : ''}
                                    sx={{background: '#fff', position: 'relative'}}
                                    onKeyUp={keyUpHandler}
                                />
                            </div>
                            <div className="row">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        label={t('startTime')}
                                        value={startTime}
                                        onChange={(newValue: Dayjs | null) => setStartTime(newValue)}
                                    />
                                </LocalizationProvider>
                                {invalidFields.startTime &&
                                    <FormHelperText
                                        className="start-time-helper-text error-text"
                                    >{t('startTimeInvalid')}</FormHelperText>
                                }
                            </div>
                        </>
                    }
                    <div className="row">
                        <TextField
                            id="title-input"
                            label={t('title')}
                            value={title}
                            placeholder={t('courseTitle')}
                            onChange={(e) => setTitle(e.target.value)}
                            error={invalidFields.title}
                            helperText={invalidFields.title ? t('titleInvalid') : ''}
                            sx={{background: '#fff', position: 'relative'}}
                            onKeyUp={keyUpHandler}
                        />
                    </div>
                    <div className="row">
                        <TextField
                            id="description-input"
                            label={t('description')}
                            value={description}
                            placeholder={t('courseDescription')}
                            onChange={(e) => setDescription(e.target.value)}
                            error={invalidFields.description}
                            helperText={invalidFields.description ? t('descriptionInvalid') : ''}
                            sx={{background: '#fff', position: 'relative'}}
                            onKeyUp={keyUpHandler}
                            multiline
                            rows={2}
                        />
                    </div>
                    <div className="row">
                        <Autocomplete
                            disablePortal
                            id="diploma-file-select"
                            options={courseSelectOptions?.diplomaFileOptions ?? []}
                            isOptionEqualToValue={diplomaFileOptionEqualToValueHandler}
                            getOptionKey={diplomaFileOptionKeyHandler}
                            getOptionLabel={diplomaFileOptionLabelHandler}
                            value={diplomaFile}
                            loading={isLoading}
                            onChange={(event: any, newValue: DiplomaFile | null): void => setDiplomaFile(newValue)}
                            renderInput={diplomaFileRenderInputHandler}
                            sx={{background: '#fff', position: 'relative'}}
                        />
                    </div>
                    <div className="row">
                        <TextField
                            id="target-audience-select"
                            label={t('targetAudience')}
                            select
                            SelectProps={{
                                multiple: true,
                                value: targetAudience,
                                onChange: targetAudienceChangeHandler,
                                input: <OutlinedInput
                                    id="select-multiple-chip"
                                    label={t('targetAudience')}
                                />,
                                renderValue: targetAudienceRenderValueHandler,
                                MenuProps: {
                                    PaperProps: {
                                        style: {
                                            maxHeight: 48 * 4.5 + 8,
                                            width: 250,
                                        },
                                    },
                                },
                            }}
                            sx={{background: '#fff', position: 'relative'}}
                        >
                            <MenuItem
                                key="technical"
                                value="technical"
                                style={targetAudienceStylesHandler('technical')}
                            >
                                {t('technical')}
                            </MenuItem>
                            <MenuItem
                                key="business"
                                value="business"
                                style={targetAudienceStylesHandler('business')}
                            >
                                {t('business')}
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="row">
                        <FormControl>
                            <TextField
                                id="duration-input"
                                label={t('duration')}
                                value={duration}
                                type="number"
                                placeholder={t('duration')}
                                onChange={durationChangeHandler}
                                inputProps={{min: 1}}
                                error={invalidFields.duration}
                                helperText={invalidFields.duration ? t('durationInvalid') : ''}
                                sx={{background: '#fff', position: 'relative'}}
                                onKeyUp={keyUpHandler}
                            />
                            <FormHelperText
                                className="duration-helper-text helper-text"
                            >
                                {t(`in${durationType[0].toUpperCase() + durationType.slice(1)}`)}
                            </FormHelperText>
                        </FormControl>
                        <TextField
                            id="duration-type-select"
                            label={t('durationType')}
                            value={durationType}
                            onChange={(e) => setDurationType((e.target.value) as DurationType)}
                            sx={{background: '#fff', position: 'relative'}}
                            select
                        >
                            <MenuItem key="minutes" value="minutes">
                                {t('minutes')}
                            </MenuItem>
                            <MenuItem key="hours" value="hours">
                                {t('hours')}
                            </MenuItem>
                            <MenuItem key="days" value="days">
                                {t('days')}
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="row">
                        <FormControlLabel
                            control={
                                <Switch
                                    value={hasPrice}
                                    checked={hasPrice}
                                    onChange={(e) => setHasPrice(e.target.checked)}
                                    sx={{
                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                            color: 'var(--background-color)',
                                        },
                                        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                                            backgroundColor: 'var(--background-color)',
                                        }
                                    }}/>
                            }
                            label={t('hasPrice')}
                            labelPlacement="start"
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: '80px',
                                paddingBottom: '24px',
                            }}
                        />
                        {hasPrice &&
                            <FormControl>
                                <TextField
                                    id="price-input"
                                    label={t('price')}
                                    value={price}
                                    type="number"
                                    placeholder={t('price')}
                                    onChange={priceChangeHandler}
                                    inputProps={{min: 1}}
                                    error={invalidFields.price}
                                    helperText={invalidFields.price ? t('priceInvalid') : ''}
                                    sx={{background: '#fff', position: 'relative'}}
                                    onKeyUp={keyUpHandler}
                                />
                                <FormHelperText
                                    className="price-helper-text helper-text"
                                >
                                    {t('inNorwegianKrone')}
                                </FormHelperText>
                            </FormControl>
                        }
                    </div>
                    <div className="row">
                        <FormControl>
                            {thumbnailSrc &&
                                <img
                                    src={thumbnailSrc}
                                    alt={title}
                                    className="thumbnail-image"
                                />
                            }
                            <Button variant="contained"
                                    className="action-button"
                                    component="label"
                            >
                                {t('uploadThumbnail')}
                                <input
                                    accept="image/*"
                                    id="thumbnail-input"
                                    type="file"
                                    onChange={thumbnailChangeHandler}
                                    hidden
                                />
                            </Button>
                            {invalidFields.thumbnail &&
                                <FormHelperText
                                    className="thumbnail-helper-text error-text"
                                >{t('thumbnailInvalid')}</FormHelperText>
                            }
                            {thumbnail &&
                                <FormHelperText
                                    className="thumbnail-helper-text helper-text"
                                >
                                    {typeof thumbnail === 'string' ? thumbnail : thumbnail.name}
                                </FormHelperText>
                            }
                        </FormControl>
                    </div>
                    <div className="row">
                        <TextField
                            id="rich-text-description-input"
                            label={t('richTextDescription')}
                            value={richTextDescription}
                            placeholder={t('richDescription')}
                            onChange={(e) => {
                                setRichTextDescription(e.target.value);
                            }}
                            sx={{background: '#fff', position: 'relative'}}
                            onKeyUp={keyUpHandler}
                            multiline
                            rows={5}
                        />
                    </div>
                    {format === 'digital' &&
                        <div className="row">
                            <FormControl>
                                <Button variant="contained"
                                        className="action-button"
                                        component="label"
                                >
                                    {t('uploadScormFile')}
                                    <input
                                        accept=".zip"
                                        id="scorm-file-input"
                                        type="file"
                                        onChange={scormFileChangeHandler}
                                        hidden
                                    />
                                </Button>
                                {invalidFields.scormFile &&
                                    <FormHelperText
                                        className="scorm-file-helper-text error-text"
                                    >{t('scormFileInvalid')}</FormHelperText>
                                }
                                {scormFile &&
                                    <FormHelperText
                                        className="scorm-file-helper-text helper-text"
                                    >
                                        {typeof scormFile === 'string' ? scormFile : scormFile.name}
                                    </FormHelperText>
                                }
                            </FormControl>
                        </div>
                    }
                    <div className="row">
                        <TextField
                            id="course-category-select"
                            label={t('courseCategory')}
                            value={courseCategory}
                            onChange={(e) => setCourseCategory((e.target.value) as CourseCategory)}
                            sx={{background: '#fff', position: 'relative'}}
                            select
                        >
                            <MenuItem key="training" value="training">
                                {t('training')}
                            </MenuItem>
                            <MenuItem key="exam" value="exam">
                                {t('exam')}
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="row">
                        <FormControlLabel
                            control={
                                <Switch
                                    value={isPublic}
                                    checked={isPublic}
                                    onChange={(e) => setIsPublic(e.target.checked)}
                                    sx={{
                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                            color: 'var(--background-color)',
                                        },
                                        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                                            backgroundColor: 'var(--background-color)',
                                        }
                                    }}/>
                            }
                            label={t('isPublic')}
                            labelPlacement="top"
                        />
                    </div>
                    {!isPublic &&
                        <div className="row">
                            <TextField
                                id="course-type-select"
                                label={t('courseType')}
                                value={courseType}
                                onChange={(e) => setCourseType((e.target.value) as CourseType)}
                                sx={{background: '#fff', position: 'relative'}}
                                select
                            >
                                <MenuItem key="optional" value="optional">
                                    {t('optional')}
                                </MenuItem>
                                <MenuItem key="mandatory" value="mandatory">
                                    {t('mandatory')}
                                </MenuItem>
                            </TextField>
                        </div>
                    }
                    <div className="row">
                        <Autocomplete
                            disablePortal
                            multiple
                            filterSelectedOptions
                            id="assigned-to-select"
                            disableCloseOnSelect={true}
                            options={courseSelectOptions?.assignedToOptions ?? []}
                            isOptionEqualToValue={assignedToOptionEqualToValueHandler}
                            getOptionLabel={assignedToOptionLabelHandler}
                            value={assignedTo}
                            loading={isLoading}
                            onChange={(event: any, newValue: AssignedTo[]): void => setAssignedTo(newValue)}
                            renderInput={assignedToRenderInputHandler}
                            sx={{background: '#fff', position: 'relative'}}
                        />
                    </div>
                    <div className="row">
                        <Autocomplete
                            disablePortal
                            multiple
                            filterSelectedOptions
                            id="assigned-to-groups-select"
                            disableCloseOnSelect={true}
                            options={courseSelectOptions?.assignedToGroupsOptions ?? []}
                            isOptionEqualToValue={assignedToGroupsOptionEqualToValueHandler}
                            getOptionLabel={assignedToGroupsOptionLabelHandler}
                            value={assignedToGroups}
                            loading={isLoading}
                            onChange={(event: any, newValue: UserGroup[]): void => setAssignedToGroups(newValue)}
                            renderInput={assignedToGroupsRenderInputHandler}
                            sx={{background: '#fff', position: 'relative'}}
                        />
                    </div>
                    <div className="row">
                        <FormControl>
                            <Autocomplete
                                disablePortal
                                id="linked-id-select"
                                options={courseSelectOptions?.linkedIdOptions ?? []}
                                isOptionEqualToValue={linkedIdOptionEqualToValueHandler}
                                getOptionLabel={linkedIdOptionLabelHandler}
                                value={linkedId}
                                loading={isLoading}
                                onChange={(event: any, newValue: LinkedId | null): void => setLinkedId(newValue)}
                                renderInput={linkedIdRenderInputHandler}
                                sx={{background: '#fff', position: 'relative'}}
                            />
                            <FormHelperText
                                className="linked-id-helper-text helper-text"
                            >
                                {t('linkedCourseHelperText')}
                            </FormHelperText>
                        </FormControl>
                    </div>
                    <Button variant="contained"
                            className="action-button"
                            onClick={submitHandler}
                            onKeyUp={keyUpHandler}
                    >
                        {t('save')}
                    </Button>
                </div>
            </div>
        </div>
    );
});

export default AdminCourse;
