"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validGroupKey = exports.validSpaces = exports.validNumber = exports.validLowercase = exports.validUppercase = exports.validPassword = exports.validPhoneNumber = exports.validEmail = void 0;
const validEmail = new RegExp(/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/);
exports.validEmail = validEmail;
const validPhoneNumber = new RegExp(/^\+\d+$/);
exports.validPhoneNumber = validPhoneNumber;
const validPassword = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d_@.*#&+-]{8,}$/);
exports.validPassword = validPassword;
const validUppercase = new RegExp(/[A-Z]/);
exports.validUppercase = validUppercase;
const validLowercase = new RegExp(/[a-z]/);
exports.validLowercase = validLowercase;
const validNumber = new RegExp(/\d/);
exports.validNumber = validNumber;
const validSpaces = new RegExp(/\s/);
exports.validSpaces = validSpaces;
const validGroupKey = new RegExp(/^[a-z0-9_-]*$/);
exports.validGroupKey = validGroupKey;
