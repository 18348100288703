import type { UserGroup, CourseHierarchy } from '../../../../server/types/admin/core';
import type {
    Format,
    CourseCategory,
    DurationType,
    CourseType,
    AssignedTo,
} from '../../../../server/types/core';

const defaultCourse: {
    title: string,
    description: string,
    targetAudience: string[],
    duration: number,
    durationType: DurationType,
    hasPrice: boolean,
    price: number,
    format: Format,
    signupLimit: number,
    location: string,
    courseType: CourseType,
    startTime: null,
    thumbnail: string,
    thumbnailSrc: string,
    richTextDescription: string,
    scormFile: string,
    courseCategory: CourseCategory,
    isPublic: boolean,
    assignedTo: AssignedTo[],
    linkedId: null,
    assignedToGroups: UserGroup[],
    courseHierarchy: CourseHierarchy,
    parentId: null,
    diplomaFile: null,
} = {
    title: '',
    description: '',
    targetAudience: [],
    duration: 60,
    durationType: 'minutes',
    hasPrice: false,
    price: 0,
    format: 'digital',
    signupLimit: 0,
    location: '',
    courseType: 'optional',
    startTime: null,
    thumbnail: '',
    thumbnailSrc: '',
    richTextDescription: '',
    scormFile: '',
    courseCategory: 'training',
    isPublic: false,
    assignedTo: [],
    linkedId: null,
    assignedToGroups: [],
    courseHierarchy: 'parent',
    parentId: null,
    diplomaFile: null,
};

export { defaultCourse }
