function getGridLocalization(translateHandler: Function, noResultText: string) {
    return {
        noRowsLabel: translateHandler(noResultText),
        noResultsOverlayLabel: translateHandler(`${noResultText}Result`),
        toolbarColumns: translateHandler('columns'),
        toolbarColumnsLabel: translateHandler('columns'),
        columnsManagementSearchTitle: translateHandler('search'),
        checkboxSelectionHeaderName: translateHandler('checkboxSelection'),
        columnsManagementShowHideAllText: translateHandler('showHideAll'),
        toolbarFilters: translateHandler('filters'),
        toolbarFiltersTooltipShow: translateHandler('filters'),
        toolbarFiltersLabel: translateHandler('filters'),
        filterPanelDeleteIconLabel: translateHandler('delete'),
        filterPanelColumns: translateHandler('columns'),
        filterPanelOperator: translateHandler('operator'),
        filterPanelInputLabel: translateHandler('value'),
        filterPanelInputPlaceholder: translateHandler('filterValue'),
        "filterOperator=": translateHandler('equalTo'),
        "filterOperator!=": translateHandler('notEqualTo'),
        "filterOperator>": translateHandler('moreThan'),
        "filterOperator>=": translateHandler('moreThanOrEqualTo'),
        "filterOperator<": translateHandler('lessThan'),
        "filterOperator<=": translateHandler('lessThanOrEqualTo'),
        filterOperatorContains: translateHandler('contains'),
        filterOperatorEquals: translateHandler('equals'),
        filterOperatorStartsWith: translateHandler('startsWith'),
        filterOperatorEndsWith: translateHandler('endsWith'),
        filterOperatorIs: translateHandler('is'),
        filterOperatorNot: translateHandler('isNot'),
        filterOperatorAfter: translateHandler('isAfter'),
        filterOperatorOnOrAfter: translateHandler('isOnOrAfter'),
        filterOperatorBefore: translateHandler('isBefore'),
        filterOperatorOnOrBefore: translateHandler('isOnOrBefore'),
        filterOperatorIsEmpty: translateHandler('isEmpty'),
        filterOperatorIsNotEmpty: translateHandler('isNotEmpty'),
        filterValueAny: translateHandler('any'),
        filterValueTrue: translateHandler('true'),
        filterValueFalse: translateHandler('false'),
        columnHeaderSortIconLabel: translateHandler('sort'),
        columnMenuLabel: translateHandler('menu'),
        columnMenuSortAsc: translateHandler('sortByAsc'),
        columnMenuSortDesc: translateHandler('sortByDesc'),
        columnMenuUnsort: translateHandler('unsort'),
        columnMenuFilter: translateHandler('filter'),
        columnMenuHideColumn: translateHandler('hideColumn'),
        columnMenuManageColumns: translateHandler('manageColumns'),
        columnMenuShowColumns: translateHandler('showColumns'),
        columnsManagementNoColumns: translateHandler('noColumns'),
        MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }: { from: number, to: number, count: number }) =>
                `${from} - ${to} ${translateHandler('of')} ${count === -1 ? `${translateHandler('moreThan')} ${to}` : count}`,
        },
    };
}

export { getGridLocalization }
