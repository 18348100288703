import React, { memo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Link } from '@tanstack/react-router';
import {
    Backdrop,
    CircularProgress,
    Theme,
    TextField,
    Button, FormHelperText, FormControl,
} from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import {
    useGetUserGroupsData,
    getUserGroup,
    createOrUpdateUserGroup,
} from '../../api/Admin/Utils';
import { UserGroup } from '../../../../server/types/admin/core';
import { toast } from 'react-toastify';
import { validGroupKey } from '../../helpers/Regex';
import { AxiosError } from 'axios';
import '../../styles/Admin/UserGroup.css';

const AdminUserGroup = memo(function AdminUserGroup(): React.ReactElement {
    const { userGroupKey }: { userGroupKey: string } = useParams({ strict: false });
    const urlPath: string = window.location.pathname;
    const { t } = useTranslation();
    const navigate = useNavigate({ from: urlPath.includes('edit') ? urlPath.replace(userGroupKey, '$userGroupKey') : urlPath });
    const [userGroup, setUserGroup] = useState<UserGroup | null>(null);
    const [groupKey, setGroupKey] = useState<string>('');
    const [groupName, setGroupName] = useState<string>('');
    const [invalidFields, setInvalidFields] = useState<{ [key: string]: boolean }>({
        groupKey: false,
        groupName: false,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const { data: userGroupsData, isLoading, error } = useGetUserGroupsData();

    if (error) {
        const { responseText } = error.request;
        const errorMessage: string = responseText ? responseText : error.message;
        toast.error(t(errorMessage), {
            hideProgressBar: true,
        });
        navigate({ to: '/manage-user-groups' });
    }

    const updateUserGroupData = useCallback(async (): Promise<void> => {
        try {
            setLoading(true);
            const userGroupData: UserGroup = await getUserGroup(userGroupKey);
            setUserGroup(userGroupData);
            setGroupKey(userGroupData.groupKey);
            setGroupName(userGroupData.groupName);
            setLoading(false);
        } catch (e: any) {
            const { responseText } = e.request;
            const errorMessage: string = responseText ? responseText : e.message;
            toast.error(t(errorMessage), {
                hideProgressBar: true,
            });
            navigate({ to: '/manage-user-groups' });
        }
    }, [userGroupKey, t, navigate]);

    useEffect(() => {
        if (userGroupKey) {
            updateUserGroupData();
        }
    }, [userGroupKey, updateUserGroupData]);

    const validateFields = useCallback((): boolean => {
        const groupKeyInvalid: boolean = groupKey === ''
            || (!userGroupKey && userGroupsData!.keys.includes(groupKey))
            || (!!userGroupKey && groupKey !== userGroup!.groupKey && userGroupsData!.keys.includes(groupKey))
            || !validGroupKey.test(groupKey);
        const groupNameInvalid: boolean = groupName === ''
            || (!userGroupKey && userGroupsData!.names.includes(groupName))
            || (!!userGroupKey && groupName !== userGroup!.groupName && userGroupsData!.names.includes(groupName));

        setInvalidFields({
            groupKey: groupKeyInvalid,
            groupName: groupNameInvalid,
        });

        return groupKeyInvalid || groupNameInvalid;
    }, [groupKey, userGroupKey, userGroupsData, userGroup, groupName]);

    const hasChanged = useCallback((): boolean => {
        return groupKey !== userGroup!.groupKey || groupName !== userGroup!.groupName;
    }, [groupKey, userGroup, groupName]);

    const submitHandler = useCallback((): void => {
        if (validateFields()) {
            toast.error(t('pleaseFillOutAllFields'), {
                hideProgressBar: true,
            });
            return;
        }

        if (userGroupKey && !hasChanged()) {
            navigate({ to: '/manage-user-groups' });
            return;
        }

        setLoading(true);
        createOrUpdateUserGroup({
            oldGroupKey: userGroupKey,
            groupKey,
            groupName,
        })
            .then((message: string) => {
                toast.success(t(message), {
                    hideProgressBar: true,
                });
                navigate({ to: '/manage-user-groups' });
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
                if (userGroupKey) {
                    updateUserGroupData();
                }
            })
            .finally(() => setLoading(false));
    }, [
        validateFields,
        userGroupKey,
        hasChanged,
        navigate,
        groupKey,
        groupName,
        t,
        updateUserGroupData,
    ]);

    const keyUpHandler = useCallback((event: React.KeyboardEvent): void => {
        if (event.key === 'Enter') {
            submitHandler();
        }
    }, [submitHandler]);

    return (
        <div className="admin-page-container user-group-container">
            <Backdrop open={isLoading || loading}
                      sx={{ zIndex: (theme: Theme) => theme.zIndex.drawer + 1 }}
            >
                <CircularProgress sx={{ color: 'var(--background-color)' }} />
            </Backdrop>
            <div className="admin-page-wrapper user-group-wrapper">
                <Link to="/manage-user-groups" className="back-link">
                    <BackIcon />
                </Link>
                <div className="admin-page-title user-group-title">{userGroupKey ? t('editUserGroup') : t('newUserGroup')}</div>
                <div className="admin-page-content user-group-content">
                    <div className="row">
                        <FormControl>
                            <TextField
                                id="group-key-input"
                                label={t('groupKey')}
                                value={groupKey}
                                placeholder={t('userGroupKey')}
                                onChange={(e) => setGroupKey(e.target.value.toLowerCase())}
                                error={invalidFields.groupKey}
                                helperText={
                                    invalidFields.groupKey
                                        ? (userGroupsData?.keys.includes(groupKey)
                                            ? t('groupKeyAlreadyInUse')
                                            : t('groupKeyInvalid'))
                                        : ''
                                }
                                sx={{background: '#fff', position: 'relative'}}
                                onKeyUp={keyUpHandler}
                            />
                            <FormHelperText
                                className="group-key-helper-text helper-text"
                            >
                                {t('groupKeyHelperText')}
                            </FormHelperText>
                        </FormControl>
                    </div>
                    <div className="row">
                        <TextField
                            id="group-name-input"
                            label={t('groupName')}
                            value={groupName}
                            placeholder={t('userGroupName')}
                            onChange={(e) => setGroupName(e.target.value)}
                            error={invalidFields.groupName}
                            helperText={
                                invalidFields.groupName
                                    ? (userGroupsData?.names.includes(groupName)
                                        ? t('groupNameAlreadyInUse')
                                        : t('groupNameInvalid'))
                                    : ''
                            }
                            sx={{background: '#fff', position: 'relative'}}
                            onKeyUp={keyUpHandler}
                        />
                    </div>
                    <Button variant="contained"
                            className="action-button"
                            onClick={submitHandler}
                            onKeyUp={keyUpHandler}
                    >
                        {t('save')}
                    </Button>
                </div>
            </div>
        </div>
    );
});

export default AdminUserGroup;
