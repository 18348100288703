import React, { memo } from 'react';
import { Link } from '@tanstack/react-router';

const NotFound = memo(function NotFound(): React.ReactElement {
    return (
        <div className="not-found-container">
            <div className="not-found-text">This page does not exist.</div>
            <Link to="/">Home</Link>
        </div>
    );
});

export default NotFound;
