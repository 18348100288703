import React, { memo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import { useGetPhysicalCourses } from '../../api/Admin/Utils';
import { LinearProgress } from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridFilterModel,
    GridSlots,
} from '@mui/x-data-grid';
import Toolbar from './Toolbar';
import { getSignupsColumns } from '../../helpers/Admin/Columns';
import { getGridLocalization } from '../../helpers/Admin/GridLocalization';
import { toast } from 'react-toastify';

const ManageSignups = memo(function ManageSignups(): React.ReactElement {
    const { t } = useTranslation();
    const navigate = useNavigate({ from: '/manage-signups' });
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const { data: physicalCourses, isLoading, error } = useGetPhysicalCourses();

    if (error) {
        const { responseText } = error.request;
        const errorMessage: string = responseText ? responseText : error.message;
        toast.error(t(errorMessage), {
            hideProgressBar: true,
        });
    }

    const translateHandler = useCallback((text: string): string => {
        return t(text);
    }, [t]);

    const navigateHandler = useCallback((path: string): void => {
        navigate({ to: path });
    }, [navigate]);

    const columns: GridColDef[] = getSignupsColumns(translateHandler, navigateHandler);

    return (
        <div className="admin-page-container manage-signups-container">
            <div className="admin-page-wrapper manage-signups-wrapper">
                <div className="admin-page-title manage-signups-title">{t('manageSignups')}</div>
                <div className="admin-page-content manage-signups-content">
                    <div className="admin-page-grid manage-signups-grid">
                        <DataGrid
                            loading={isLoading}
                            columns={columns}
                            rows={physicalCourses ?? []}
                            slots={{
                                toolbar: Toolbar,
                                loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
                            }}
                            sx={{
                                color: 'var(--background-color)',
                                border: 'none',
                                '& .MuiDataGrid-toolbarContainer': {
                                    background: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    color: 'var(--background-color)',
                                    padding: '10px',
                                },
                                '& .MuiDataGrid-topContainer::after': {
                                    background: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-main > *:first-of-type': {
                                    borderRadius: 0,
                                },
                                '& .MuiDataGrid-topContainer': {
                                    borderBottom: '1px solid var(--background-color)',
                                },
                                '& .MuiDataGrid-columnHeaders [role="row"]': {
                                    background: 'var(--text-color)',
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '& .MuiDataGrid-columnSeparator:hover': {
                                    color: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-withBorderColor': {
                                    borderColor: 'var(--background-color)',
                                },
                                '& .MuiCheckbox-root': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiCheckbox-root.Mui-checked': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-virtualScrollerContent .MuiCheckbox-root': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-virtualScrollerContent .MuiCheckbox-root.Mui-checked': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-overlayWrapperInner > div': {
                                    background: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-filler': {
                                    display: 'none',
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    background: 'var(--text-color)',
                                    borderColor: 'var(--background-color)',
                                },
                                '& .MuiTablePagination-toolbar': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiTablePagination-selectIcon': {
                                    color: 'var(--background-color)',
                                },
                            }}
                            slotProps={{
                                loadingOverlay: {
                                    sx: {
                                        backgroundColor: 'var(--text-color)',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: 'var(--button-color)',
                                        },
                                    },
                                },
                                pagination: {
                                    labelRowsPerPage: t('rowsPerPage'),
                                },
                            }}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 20 } },
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                            disableRowSelectionOnClick
                            localeText={getGridLocalization(translateHandler, 'noCourses')}
                            pageSizeOptions={[20, 50, 100]}
                            checkboxSelection={false}
                            onFilterModelChange={(newFilterModel: GridFilterModel): void => {
                                setFilterModel(newFilterModel);
                            }}
                            filterModel={filterModel}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ManageSignups;
