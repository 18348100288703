import React, { memo } from 'react';
import { useGetPageElements } from '../api/Sanity';
import { toast } from 'react-toastify';
import { PortableText } from '@portabletext/react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import type { PageElement } from '../../../server/types/sanity';
import '../styles/About.css';

const About = memo(function About(): React.ReactElement {
    const { i18n, t } = useTranslation();
    const language: string = i18n.language ?? 'en';
    const { data: pageElements, isLoading, error } = useGetPageElements('about', language);

    if (error) {
        const { responseText } = error.request;
        const errorMessage: string = responseText ? responseText : error.message;
        toast.error(t(errorMessage), {
            hideProgressBar: true,
        });
    }

    return (
        <div className="about-page-container">
            { isLoading && <Skeleton
                variant="rounded"
                animation="wave"
                className="about-page-skeleton"
            /> }
            { !isLoading && pageElements?.map((pageElement: PageElement) => <div className={`content-block ${pageElement._type}`} key={pageElement._key}>
                { pageElement._type === 'header' &&
                    <h1>{pageElement.title!}</h1>
                }
                { pageElement._type === 'textBlock' &&
                    <div className="content-block-content">
                        <PortableText value={pageElement.content!} />
                    </div>
                }
            </div>) }
        </div>
    );
});

export default About;
