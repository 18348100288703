import React, { memo } from 'react';
import { useGetPageElements } from '../api/Sanity';
import { toast } from 'react-toastify';
import { PortableText } from '@portabletext/react';
import CenteredHero from './CenteredHero';
import ImageHero from './ImageHero';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import type { PageElement } from '../../../server/types/sanity';
import '../styles/Homepage.css';

const Homepage = memo(function Homepage(): React.ReactElement {
    const { i18n, t } = useTranslation();
    const language: string = i18n.language ?? 'en';
    const { data: pageElements, isLoading, error } = useGetPageElements('homepage', language);

    if (error) {
        const { responseText } = error.request;
        const errorMessage: string = responseText ? responseText : error.message;
        toast.error(t(errorMessage), {
            hideProgressBar: true,
        });
    }

    return (
        <div className="homepage-container">
            { isLoading && <Skeleton
                variant="rounded"
                animation="wave"
                className="homepage-skeleton"
            /> }
            { !isLoading && pageElements?.map((pageElement: PageElement) => <div className={`content-block ${pageElement._type}`} key={pageElement._key}>
                    { pageElement._type === 'imageBlock' &&
                        <ImageHero
                            title={pageElement.title!}
                            subtitle={pageElement.subtitle!}
                            image={pageElement.image!.asset.url!}
                            label={pageElement.cta!.label}
                            url={pageElement.cta!.link}
                        />
                    }
                    { pageElement._type === 'bannerBlock' &&
                        <CenteredHero
                            title={pageElement.title!}
                            subtitle={pageElement.subtitle!}
                            firstLabel={pageElement.doubleCta!.firstLabel}
                            firstUrl={pageElement.doubleCta!.firstLink}
                            secondLabel={pageElement.doubleCta!.secondLabel}
                            secondUrl={pageElement.doubleCta!.secondLink}
                        />
                    }
                    { pageElement._type === 'textBlock' &&
                        <div className="content-block-content">
                            <PortableText value={pageElement.content!} />
                        </div>
                    }
                </div>) }
        </div>
    );
});

export default Homepage;
