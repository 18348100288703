import axios from 'axios';
import imageCompression from 'browser-image-compression';

function getToken(): string | null {
    return localStorage.getItem('admin-auth-token');
}

async function requestHandler<ReturnType>(
    {
        type,
        endpoint,
        data,
    }: {
        type: 'post' | 'formData',
        endpoint: string,
        data?: { [key: string]: any },
    }
): Promise<ReturnType> {
    const token: string | null = getToken();

    if (!token) {
        return new Promise((resolve, reject) => reject('noTokenProvided'));
    }

    switch (type) {
        case 'post':
            return axios.post<ReturnType>(endpoint, { ...data, token })
                .then((response) => response.data);
        case 'formData':
            const entries: [string, any][] = Object.entries({ ...data, token });
            let formData = new FormData();

            for (const [key, value] of entries) {
                if (value instanceof Object && !Array.isArray(value)) {
                    const innerEntries: [string, any][] = Object.entries(value);

                    for (const [innerKey, innerValue] of innerEntries) {
                        if (innerValue instanceof File) {
                            formData = await appendToFormData(formData, [key + innerKey[0].toUpperCase() + innerKey.slice(1), innerValue]);
                            value[innerKey] = null;
                        }
                    }
                }

                formData = await appendToFormData(formData, [key, value]);
            }

            return axios.post<ReturnType>(endpoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => response.data);
        default:
            return new Promise((resolve, reject) => reject('invalidRequestType'));
    }
}

async function appendToFormData(formData: FormData, [key, value]: [string, any]): Promise<FormData> {
    if (value instanceof File) {
        if (value.type.includes('image/')) {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            }
            const compressedImage = await imageCompression(value, options);

            formData.append('files', compressedImage);
            return formData;
        }

        formData.append('files', value);
        return formData;
    }

    if (typeof value === 'string') {
        formData.append(key, value);
        return formData;
    }

    formData.append(key, JSON.stringify(value));
    return formData;
}

export { getToken, requestHandler }
