import React, { memo, useState, useCallback, cloneElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Checkbox } from '@mui/material';
import type { Tag } from '../../../server/types/core';
import '../styles/CourseFilters.css';

const CourseFilters = memo(function CourseFilters(
    {
        activeTag,
        children,
    }: {
        activeTag: Tag,
        children: React.ReactElement,
    }
): React.ReactElement {
    const { t } = useTranslation();
    const [coursesFilterState, setCoursesFilterState] = useState<string>('');
    const [myCoursesFilterState, setMyCoursesFilterState] = useState<string>('');

    const coursesChangeHandler = useCallback((
        event: React.ChangeEvent<HTMLInputElement>,
        state: 'technical' | 'business',
    ) => {
        if (event.target.checked) {
            setCoursesFilterState(state);
            return;
        }

        setCoursesFilterState('');
    }, []);

    const myCoursesChangeHandler = useCallback((
        event: React.ChangeEvent<HTMLInputElement>,
        state: 'in_progress' | 'completed',
    ) => {
        if (event.target.checked) {
            setMyCoursesFilterState(state);
            return;
        }

        setMyCoursesFilterState('');
    }, []);

    return (
        <>
            <div className="course-filters-container">
                <div className="course-filters-title">{activeTag === 'my_courses' ? t('filterBy') : t('filterByTargetAudience')}</div>
                <div className="course-filters">
                    {activeTag === 'courses' &&
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={coursesFilterState === 'technical'}
                                        onChange={
                                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                                coursesChangeHandler(event, 'technical')
                                            }
                                        }
                                        sx={{
                                            color: 'var(--background-color)',
                                            '&.Mui-checked': {
                                                color: 'var(--background-color)',
                                            },
                                        }}
                                    />
                                }
                                label={t('technical')}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={coursesFilterState === 'business'}
                                        onChange={
                                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                                coursesChangeHandler(event, 'business')
                                            }
                                        }
                                        sx={{
                                            color: 'var(--background-color)',
                                            '&.Mui-checked': {
                                                color: 'var(--background-color)',
                                            },
                                        }}
                                    />
                                }
                                label={t('business')}
                            />
                        </>
                    }
                    {activeTag === 'my_courses' &&
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={myCoursesFilterState === 'in_progress'}
                                        onChange={
                                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                                myCoursesChangeHandler(event, 'in_progress')
                                            }
                                        }
                                        sx={{
                                            color: 'var(--background-color)',
                                            '&.Mui-checked': {
                                                color: 'var(--background-color)',
                                            },
                                        }}
                                    />
                                }
                                label={t('inProgress')}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={myCoursesFilterState === 'completed'}
                                        onChange={
                                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                                myCoursesChangeHandler(event, 'completed')
                                            }
                                        }
                                        sx={{
                                            color: 'var(--background-color)',
                                            '&.Mui-checked': {
                                                color: 'var(--background-color)',
                                            },
                                        }}
                                    />
                                }
                                label={t('completed')}
                            />
                        </>
                    }
                </div>
            </div>
            { cloneElement(children, { coursesFilterState, myCoursesFilterState }) }
        </>
    );
});

export default CourseFilters;
