import { createContext } from 'react';
import type { User } from '../../../../server/types/admin/core';

export const AuthContext = createContext<{
    loggedIn: boolean,
    updateLoginState: Function,
    user: null | User,
}>(
    {
        loggedIn: false,
        updateLoginState: () => {},
        user: null,
    }
);
