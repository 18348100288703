import React, { memo, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Link } from '@tanstack/react-router';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import { useGetCourseSignups, updateSignups } from '../../api/Admin/Utils';
import Toolbar from './Toolbar';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormGroup,
    DialogTitle,
    LinearProgress,
    FormControlLabel,
    Checkbox,
    Theme,
    CircularProgress,
    Backdrop,
} from '@mui/material';
import { getGridLocalization } from '../../helpers/Admin/GridLocalization';
import { getSignupListColumns } from '../../helpers/Admin/Columns';
import { toast } from 'react-toastify';
import { DataGrid, GridColDef, GridFilterModel, GridSlots } from '@mui/x-data-grid';
import { Signup } from '../../../../server/types/core';
import { AxiosError } from 'axios';

const SignupList = memo(function SignupList(): React.ReactElement {
    const { courseId }: { courseId: string } = useParams({ strict: false });
    const urlPath: string = window.location.pathname;
    const { t } = useTranslation();
    const navigate = useNavigate({ from: urlPath.replace(courseId, '$courseId') });
    const [loading, setLoading] = useState<boolean>(false);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [selectedAttendees, setSelectedAttendees] = useState<number[]>([]);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const { data: signups, isLoading, error, refetch } = useGetCourseSignups(parseInt(courseId));
    const unselectedAttendees: number[] = useMemo(() => !signups ? [] : signups.map((signup: Signup) => signup.id)
        .filter((userId: number) => !selectedAttendees.includes(userId)), [signups, selectedAttendees]);

    if (error) {
        const { responseText } = error.request;
        const errorMessage: string = responseText ? responseText : error.message;
        toast.error(t(errorMessage), {
            hideProgressBar: true,
        });
        navigate({ to: '/manage-signups' });
    }

    const translateHandler = useCallback((text: string): string => {
        return t(text);
    }, [t]);

    const columns: GridColDef[] = getSignupListColumns(translateHandler);

    const changeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedAttendees([
                ...selectedAttendees,
                parseInt(event.target.name),
            ]);
            return;
        }

        setSelectedAttendees([
            ...selectedAttendees.filter((selectedAttendee: number): boolean => selectedAttendee !== parseInt(event.target.name)),
        ]);
    }, [selectedAttendees]);

    const dialogHandler = useCallback(() => {
        setDialogOpen(false);
        setLoading(true);
        updateSignups({
            courseId: parseInt(courseId),
            selectedAttendees,
            unselectedAttendees,
        })
            .then((message: string): void => {
                toast.success(t(message), {
                    hideProgressBar: true,
                });
                refetch();
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
            })
            .finally((): void => setLoading(false));
    }, [courseId, selectedAttendees, unselectedAttendees, t, refetch]);

    return (
        <div className="admin-page-container signup-list-container">
            <Backdrop open={loading}
                      sx={{ zIndex: (theme: Theme) => theme.zIndex.drawer + 1 }}
            >
                <CircularProgress sx={{ color: 'var(--background-color)' }} />
            </Backdrop>
            <div className="admin-page-wrapper signup-list-wrapper">
                <Link to="/manage-signups" className="back-link">
                    <BackIcon />
                </Link>
                <div className="admin-page-title signup-list-title">{t('signupList')}</div>
                <div className="admin-page-content signup-list-content">
                    <div className="admin-page-controls signup-list-controls">
                        {signups?.length! > 0 &&
                            <Button variant="contained"
                                    className="action-button"
                                    onClick={() => setDialogOpen(true)}
                            >{t('selectUserAttendance')}</Button>
                        }
                        <Dialog
                            open={dialogOpen}
                            onClose={() => setDialogOpen(false)}
                            aria-labelledby="dialog-title"
                        >
                            <DialogTitle id="dialog-title">{t('selectUsersWhoAttended')}</DialogTitle>
                            <DialogContent>
                                <FormGroup>
                                    {signups?.map((signup: Signup) => {
                                        return (<FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedAttendees.includes(signup.id)}
                                                    onChange={changeHandler}
                                                    name={signup.id.toString()}
                                                />
                                            }
                                            label={`${signup.name} [${signup.email}]`}
                                        />);
                                    })}
                                </FormGroup>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    className="action-button"
                                    onClick={dialogHandler}
                                    autoFocus
                                    disabled={!dialogOpen}>{t('submit')}</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    <div className="admin-page-grid signup-list-grid">
                        <DataGrid
                            loading={isLoading}
                            columns={columns}
                            rows={signups ?? []}
                            slots={{
                                toolbar: Toolbar,
                                loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
                            }}
                            sx={{
                                color: 'var(--background-color)',
                                border: 'none',
                                '& .MuiDataGrid-toolbarContainer': {
                                    background: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    color: 'var(--background-color)',
                                    padding: '10px',
                                },
                                '& .MuiDataGrid-topContainer::after': {
                                    background: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-main > *:first-of-type': {
                                    borderRadius: 0,
                                },
                                '& .MuiDataGrid-topContainer': {
                                    borderBottom: '1px solid var(--background-color)',
                                },
                                '& .MuiDataGrid-columnHeaders [role="row"]': {
                                    background: 'var(--text-color)',
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '& .MuiDataGrid-columnSeparator:hover': {
                                    color: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-withBorderColor': {
                                    borderColor: 'var(--background-color)',
                                },
                                '& .MuiCheckbox-root': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiCheckbox-root.Mui-checked': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-virtualScrollerContent .MuiCheckbox-root': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-virtualScrollerContent .MuiCheckbox-root.Mui-checked': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-overlayWrapperInner > div': {
                                    background: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-filler': {
                                    display: 'none',
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    background: 'var(--text-color)',
                                    borderColor: 'var(--background-color)',
                                },
                                '& .MuiTablePagination-toolbar': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiTablePagination-selectIcon': {
                                    color: 'var(--background-color)',
                                },
                            }}
                            slotProps={{
                                loadingOverlay: {
                                    sx: {
                                        backgroundColor: 'var(--text-color)',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: 'var(--button-color)',
                                        },
                                    },
                                },
                                pagination: {
                                    labelRowsPerPage: t('rowsPerPage'),
                                },
                            }}
                            initialState={{
                                pagination: {paginationModel: {pageSize: 20}},
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                            disableRowSelectionOnClick
                            localeText={getGridLocalization(translateHandler, 'noSignups')}
                            pageSizeOptions={[20, 50, 100]}
                            checkboxSelection={false}
                            onFilterModelChange={(newFilterModel: GridFilterModel): void => {
                                setFilterModel(newFilterModel);
                            }}
                            filterModel={filterModel}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default SignupList;
