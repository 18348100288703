import React, { memo, useContext, useState, useMemo } from 'react';
import { Button } from '@mui/material';
import { AuthContext } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import CourseListSkeleton from './CourseListSkeleton';
import CourseItem from './CourseItem';
import { toast } from 'react-toastify';
import { useGetCourses } from '../api/Utils';
import type { Course, Tag } from '../../../server/types/core';
import '../styles/CourseList.css';

const CourseList = memo(function CourseList(
    {
        activeTag,
        coursesFilterState,
        myCoursesFilterState
    }: {
        activeTag: Tag,
        coursesFilterState?: string,
        myCoursesFilterState?: string,
    }
): React.ReactElement {
    const { user } = useContext(AuthContext);
    const { t } = useTranslation();
    const [coursesShown, setCoursesShown] = useState<number>(6);
    const { data: courses, isLoading, error } = useGetCourses(user?.id, user?.userGroup as string);
    const filteredCourses = useMemo((): Course[] => {
        if (activeTag === 'my_courses') {
            const filteredStatus = myCoursesFilterState === 'in_progress'
                ? ['not attempted', 'incomplete', 'signed up', 'failed']
                : ['passed', 'complete', 'completed'];
            return !courses ? [] : (!myCoursesFilterState ? courses.myCourses : courses.myCourses.filter((course: Course): boolean => {
                return filteredStatus.includes(course.status!);
            }))
        }

        return !courses ? [] : (!coursesFilterState ? courses.courses : courses.courses.filter((course: Course): boolean => {
            return course.targetAudience.includes(coursesFilterState);
        }));
    }, [activeTag, courses, myCoursesFilterState, coursesFilterState]);

    if (error) {
        const { responseText } = error.request;
        const errorMessage: string = responseText ? responseText : error.message;
        toast.error(t(errorMessage), {
            hideProgressBar: true,
        });
    }

    return (
        <div className="course-list-container">
            { isLoading && <CourseListSkeleton /> }
            { !isLoading &&
                <div className="course-list-wrapper">
                    {filteredCourses!.length > 0 &&
                        <div className="course-list">
                            <div className="course-list-content">
                                {filteredCourses!.slice(0, coursesShown).map((course: Course, index: number) => (
                                    <CourseItem course={course} key={index}/>
                                ))}
                            </div>
                            {filteredCourses!.length > coursesShown &&
                                <Button variant="contained"
                                        className="load-more-button"
                                        onClick={() => setCoursesShown((prevAmount: number) => prevAmount + 6)}
                                >{t('loadMore')}</Button>
                            }
                        </div>
                    }
                    {!filteredCourses?.length &&
                        <div className="no-courses-container">
                            <div className="no-courses-title">
                                {activeTag === 'my_courses' ? t('noCoursesToComplete') : t('noCoursesAvailable')}
                            </div>
                            <div className="no-courses-subtitle">
                                {activeTag === 'my_courses' ? t('hereYourCourses') : t('herePublicCourses')}
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
});

export default CourseList;
