import React, { memo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Link } from '@tanstack/react-router';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import { useGetCompletedCourses } from '../../api/Admin/Utils';
import Toolbar from './Toolbar';
import {
    LinearProgress,
} from '@mui/material';
import { getGridLocalization } from '../../helpers/Admin/GridLocalization';
import { getCompletedCoursesColumns } from '../../helpers/Admin/Columns';
import { toast } from 'react-toastify';
import { DataGrid, GridColDef, GridFilterModel, GridSlots } from '@mui/x-data-grid';

const CompletedCourses = memo(function CompletedCourses(): React.ReactElement {
    const { userId }: { userId: string } = useParams({ strict: false });
    const urlPath: string = window.location.pathname;
    const { t } = useTranslation();
    const navigate = useNavigate({ from: urlPath.replace(userId, '$userId') });
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const { data: completedCourses, isLoading, error } = useGetCompletedCourses(parseInt(userId));

    if (error) {
        const { responseText } = error.request;
        const errorMessage: string = responseText ? responseText : error.message;
        toast.error(t(errorMessage), {
            hideProgressBar: true,
        });
        navigate({ to: '/manage-users' });
    }

    const translateHandler = useCallback((text: string): string => {
        return t(text);
    }, [t]);

    const columns: GridColDef[] = getCompletedCoursesColumns(translateHandler);

    return (
        <div className="admin-page-container completed-courses-container">
            <div className="admin-page-wrapper completed-courses-wrapper">
                <Link to="/manage-users" className="back-link">
                    <BackIcon />
                </Link>
                <div className="admin-page-title completed-courses-title">{t('completedCourses')}</div>
                <div className="admin-page-content completed-courses-content">
                    <div className="admin-page-grid completed-courses-grid">
                        <DataGrid
                            loading={isLoading}
                            columns={columns}
                            rows={completedCourses ?? []}
                            slots={{
                                toolbar: Toolbar,
                                loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
                            }}
                            sx={{
                                color: 'var(--background-color)',
                                border: 'none',
                                '& .MuiDataGrid-toolbarContainer': {
                                    background: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    color: 'var(--background-color)',
                                    padding: '10px',
                                },
                                '& .MuiDataGrid-topContainer::after': {
                                    background: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-main > *:first-of-type': {
                                    borderRadius: 0,
                                },
                                '& .MuiDataGrid-topContainer': {
                                    borderBottom: '1px solid var(--background-color)',
                                },
                                '& .MuiDataGrid-columnHeaders [role="row"]': {
                                    background: 'var(--text-color)',
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '& .MuiDataGrid-columnSeparator:hover': {
                                    color: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-withBorderColor': {
                                    borderColor: 'var(--background-color)',
                                },
                                '& .MuiCheckbox-root': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiCheckbox-root.Mui-checked': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-virtualScrollerContent .MuiCheckbox-root': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-virtualScrollerContent .MuiCheckbox-root.Mui-checked': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-overlayWrapperInner > div': {
                                    background: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-filler': {
                                    display: 'none',
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    background: 'var(--text-color)',
                                    borderColor: 'var(--background-color)',
                                },
                                '& .MuiTablePagination-toolbar': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiTablePagination-selectIcon': {
                                    color: 'var(--background-color)',
                                },
                            }}
                            slotProps={{
                                loadingOverlay: {
                                    sx: {
                                        backgroundColor: 'var(--text-color)',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: 'var(--button-color)',
                                        },
                                    },
                                },
                                pagination: {
                                    labelRowsPerPage: t('rowsPerPage'),
                                },
                            }}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 20 } },
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        description: false,
                                        richTextDescription: false,
                                        assignedTo: false,
                                        linkedId: false,
                                        assignedToGroups: false,
                                        parentId: false,
                                    },
                                },
                            }}
                            disableRowSelectionOnClick
                            localeText={getGridLocalization(translateHandler, 'noCompletedCourses')}
                            pageSizeOptions={[20, 50, 100]}
                            checkboxSelection={false}
                            onFilterModelChange={(newFilterModel: GridFilterModel): void => {
                                setFilterModel(newFilterModel);
                            }}
                            filterModel={filterModel}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default CompletedCourses;
