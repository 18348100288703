import React, { memo, useState, useCallback } from 'react';
import {
    Backdrop,
    CircularProgress,
    Theme,
    TextField,
    FormHelperText,
    Button,
} from '@mui/material';
import { Link, useNavigate } from '@tanstack/react-router';
import { AxiosError } from 'axios';
import { registerUser } from '../api/Utils';
import {
    validEmail,
    validPhoneNumber,
    validPassword,
    validUppercase,
    validLowercase,
    validNumber,
    validSpaces,
} from '../helpers/Regex';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import '../styles/Register.css';

const Register = memo(function Register(): React.ReactElement {
    const { t } = useTranslation();
    const navigate = useNavigate({ from: '/register' });
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [companyAddress, setCompanyAddress] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [repeatPassword, setRepeatPassword] = useState<string>('');
    const [invalidFields, setInvalidFields] = useState<{ [key: string]: boolean }>({
        name: false,
        email: false,
        phoneNumber: false,
        companyName: false,
        companyAddress: false,
        password: false,
        repeatPassword: false,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const uppercase = validUppercase.test(password);
    const lowercase = validLowercase.test(password);
    const number = validNumber.test(password);
    const eightCharacters = password.length >= 8;
    const spaces = !validSpaces.test(password);

    const validateFields = useCallback((): boolean => {
        const nameInvalid: boolean = name === '';
        const emailInvalid: boolean = email === '' || !validEmail.test(email);
        const phoneNumberInvalid: boolean = phoneNumber === '' || !validPhoneNumber.test(phoneNumber);
        const companyNameInvalid: boolean = companyName === '';
        const companyAddressInvalid: boolean = companyAddress === '';
        const passwordInvalid: boolean = password === '' || !validPassword.test(password);
        const repeatPasswordInvalid: boolean = repeatPassword !== password;

        setInvalidFields({
            name: nameInvalid,
            email: emailInvalid,
            phoneNumber: phoneNumberInvalid,
            companyName: companyNameInvalid,
            companyAddress: companyAddressInvalid,
            password: passwordInvalid,
            repeatPassword: repeatPasswordInvalid,
        });

        return nameInvalid
            || emailInvalid
            || phoneNumberInvalid
            || companyNameInvalid
            || companyAddressInvalid
            || passwordInvalid
            || repeatPasswordInvalid;
    }, [name, email, phoneNumber, companyName, companyAddress, password, repeatPassword]);

    const submitHandler = useCallback((): void => {
        if (validateFields()) {
            return;
        }

        setLoading(true);
        registerUser({
            name,
            email,
            phoneNumber,
            companyName,
            companyAddress,
            password,
        })
            .then((message: string) => {
                toast.success(t(message), {
                    hideProgressBar: true,
                });
                navigate({ to: '/login' });
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
            })
            .finally(() => setLoading(false));
    }, [
        validateFields,
        name,
        email,
        phoneNumber,
        companyName,
        companyAddress,
        password,
        t,
        navigate,
    ]);

    const keyUpHandler = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            submitHandler();
        }
    }, [submitHandler]);

    return (
        <div className="register-container">
            <Backdrop open={loading}
                      sx={{ zIndex: (theme: Theme) => theme.zIndex.drawer + 1 }}
            >
                <CircularProgress sx={{ color: 'var(--background-color)' }} />
            </Backdrop>
            <div className="register-form-wrapper">
                <div className="register-title">{t('signUp')}</div>
                <a href="/sso/login" className="sso-register-link">{t('signUpWithSso')}</a>
                <div className="row">
                    <TextField
                        id="name-input"
                        label={t('name')}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={invalidFields.name}
                        helperText={invalidFields.name ? t('nameInvalid') : ''}
                        sx={{background: '#fff', position: 'relative'}}
                        onKeyUp={keyUpHandler}
                    />
                    <TextField
                        id="email-input"
                        label={t('email')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={invalidFields.email}
                        helperText={invalidFields.email ? t('emailInvalid') : ''}
                        sx={{background: '#fff', position: 'relative'}}
                        onKeyUp={keyUpHandler}
                    />
                    <TextField
                        id="phone-number-input"
                        label={t('phoneNumber')}
                        value={phoneNumber}
                        placeholder="+1234567890"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        error={invalidFields.phoneNumber}
                        helperText={invalidFields.phoneNumber ? t('phoneNumberInvalid') : ''}
                        sx={{background: '#fff', position: 'relative'}}
                        onKeyUp={keyUpHandler}
                    />
                </div>
                <div className="row">
                    <TextField
                        id="company-name-input"
                        label={t('companyName')}
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        error={invalidFields.companyName}
                        helperText={invalidFields.companyName ? t('companyNameInvalid') : ''}
                        sx={{background: '#fff', position: 'relative'}}
                        onKeyUp={keyUpHandler}
                    />
                    <TextField
                        id="company-address-input"
                        label={t('companyAddress')}
                        value={companyAddress}
                        onChange={(e) => setCompanyAddress(e.target.value)}
                        error={invalidFields.companyAddress}
                        helperText={invalidFields.companyAddress ? t('companyAddressInvalid') : ''}
                        sx={{background: '#fff', position: 'relative'}}
                        onKeyUp={keyUpHandler}
                    />
                </div>
                <div className="row">
                    <TextField
                        id="password-input"
                        label={t('password')}
                        type="password"
                        autoComplete="off"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={invalidFields.password}
                        helperText={invalidFields.password ? t('registerPasswordInvalid') : ''}
                        sx={{background: '#fff', position: 'relative'}}
                        onKeyUp={keyUpHandler}
                    />
                    <TextField
                        id="repeat-password-input"
                        label={t('repeatPassword')}
                        type="password"
                        autoComplete="off"
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                        error={invalidFields.repeatPassword}
                        helperText={invalidFields.repeatPassword ? t('repeatPasswordInvalid') : ''}
                        sx={{background: '#fff', position: 'relative'}}
                        onKeyUp={keyUpHandler}
                    />
                </div>
                <ul className="password-strength-container">
                    <li>{t('passwordStrength')}</li>
                    <li className={uppercase ? 'active' : ''}>
                        <div className="password-strength-text">{t('oneUpperCaseLetter')}</div>
                    </li>
                    <li className={lowercase ? 'active' : ''}>
                    <div className="password-strength-text">{t('oneLowerCaseLetter')}</div>
                    </li>
                    <li className={number ? 'active' : ''}>
                    <div className="password-strength-text">{t('oneNumber')}</div>
                    </li>
                    <li className={eightCharacters ? 'active' : ''}>
                        <div className="password-strength-text">{t('minimumEightCharacters')}</div>
                    </li>
                    <li className={spaces ? 'active' : ''}>
                        <div className="password-strength-text">{t('noSpaces')}</div>
                    </li>
                </ul>
                <Button variant="contained"
                        onClick={() => submitHandler()}
                        onKeyUp={keyUpHandler}
                >{t('register')}</Button>
                <FormHelperText className="login-helper-text">
                    {t('account?')}
                    <Link to="/login" className="login-link">{t('login')}</Link>
                </FormHelperText>
            </div>
        </div>
    );
});

export default Register;
