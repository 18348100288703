import React, { memo } from 'react';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';

const Toolbar = memo(function Toolbar(): React.ReactElement {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
});

export default Toolbar;
