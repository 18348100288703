import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import en from './locales/en/translation.json';
import no from './locales/no/translation.json';

const resources = {
    en: {
        translation: en
    },
    no: {
        translation: no
    }
};

i18n.use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem('preferred-language') ?? 'en',
        fallbackLng: 'en',
        debug: false,
    });

export default i18n;
