import React, { memo, useContext, useCallback } from 'react';
import { Link, useNavigate } from '@tanstack/react-router';
import {
    AutoAwesomeMotion,
    Person,
    PersonAdd,
    Group,
    Settings,
    Logout,
} from '@mui/icons-material';
import { logoutUser } from '../../api/Admin/Utils';
import { AuthContext } from '../../contexts/Admin/AuthContext';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import logo from '../../media/logo.svg';
import smallLogo from '../../media/small-logo.svg';
import '../../styles/Admin/Menu.css';

const Menu = memo(function Menu(): React.ReactElement {
    const { user, updateLoginState } = useContext(AuthContext);
    const navigate = useNavigate({ from: window.location.pathname });
    const { t } = useTranslation();

    const logoutHandler = useCallback((): void => {
        logoutUser(user!.id!)
            .then(() => {
                localStorage.removeItem('admin-auth-token');
                updateLoginState();
                navigate({ to: '/admin' });
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
            });
    }, [user, updateLoginState, navigate, t]);

    return (
        <div className="menu-container">
            <Link to="/dashboard" className="menu-link logo-link">
                <img src={logo} className="menu-logo" alt="logo"/>
                <img src={smallLogo} className="small-menu-logo" alt="logo"/>
            </Link>
            <div className="menu-wrapper">
            <Link to="/manage-courses" className="menu-link manage-courses-link">
                    <AutoAwesomeMotion
                        className="menu-link-icon manage-courses-icon"
                        sx={{ height: '20px' }}
                    />
                    <span className="menu-link-text manage-courses-text">{t('manageCourses')}</span>
                </Link>
                <Link to="/manage-users" className="menu-link manage-users-link">
                    <Person
                        className="menu-link-icon manage-users-icon"
                        sx={{ height: '20px' }}
                    />
                    <span className="menu-link-text manage-users-text">{t('manageUsers')}</span>
                </Link>
                <Link to="/manage-signups" className="menu-link manage-signups-link">
                    <PersonAdd
                        className="menu-link-icon manage-signups-icon"
                        sx={{ height: '20px' }}
                    />
                    <span className="menu-link-text manage-signups-text">{t('manageSignups')}</span>
                </Link>
                <Link to="/manage-user-groups" className="menu-link manage-user-groups-link">
                    <Group
                        className="menu-link-icon manage-user-groups-icon"
                        sx={{ height: '20px' }}
                    />
                    <span className="menu-link-text manage-user-groups-text">{t('manageUserGroups')}</span>
                </Link>
                <Link to="/configurations" className="menu-link configurations-link">
                    <Settings
                        className="menu-link-icon configurations-icon"
                        sx={{ height: '20px' }}
                    />
                    <span className="menu-link-text configurations-text">{t('configurations')}</span>
                </Link>
                <Link
                    to={window.location.pathname}
                    onClick={logoutHandler}
                    className="menu-link logout-link"
                >
                    <Logout
                        className="menu-link-icon logout-icon"
                        sx={{ height: '20px' }}
                    />
                    <span className="menu-link-text logout-text">{t('logout')}</span>
                </Link>
            </div>
        </div>
    );
});

export default Menu;
