import React, { memo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@tanstack/react-router';
import {
    useGetDeletedCourses,
    restoreCourse,
    restoreCourses,
    deleteCourse,
} from '../../api/Admin/Utils';
import {
    Backdrop,
    CircularProgress,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    LinearProgress,
    Theme,
    DialogContentText,
    TextField,
    DialogContent,
} from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridFilterModel,
    GridRowId,
    GridRowSelectionModel,
    GridSlots,
} from '@mui/x-data-grid';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import Toolbar from './Toolbar';
import { getDeletedCoursesColumns } from '../../helpers/Admin/Columns';
import { getGridLocalization } from '../../helpers/Admin/GridLocalization';
import { toast } from 'react-toastify';
import { Course } from '../../../../server/types/core';
import { AxiosError } from 'axios';

const DeletedCourses = memo(function DeletedCourses(): React.ReactElement {
    const { t } = useTranslation();
    const [singleRestoreDialogOpen, setSingleRestoreDialogOpen] = useState<boolean>(false);
    const [restoreDialogOpen, setRestoreDialogOpen] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [loading, setLoading] = useState<boolean>(false);
    const [restoreId, setRestoreId] = useState<number | null>(null);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [signupAmount, setSignupAmount] = useState<number>(0);
    const [deleteInput, setDeleteInput] = useState<string>('');
    const { data: deletedCourses, isLoading, error, refetch } = useGetDeletedCourses();

    if (error) {
        const { responseText } = error.request;
        const errorMessage: string = responseText ? responseText : error.message;
        toast.error(t(errorMessage), {
            hideProgressBar: true,
        });
    }

    const translateHandler = useCallback((text: string): string => {
        return t(text);
    }, [t]);

    const dialogHandler = useCallback((
        state: boolean,
        id?: GridRowId,
        action: string = 'restore',
    ): void => {
        switch (action) {
            case 'restore':
                if (state) {
                    setRestoreId(id as number);
                    setSingleRestoreDialogOpen(state);
                    return;
                }

                setSingleRestoreDialogOpen(state);
                setRestoreId(null);
                break;
            case 'delete':
                if (state) {
                    const courseToDelete: Course = deletedCourses!.find((course: Course): boolean => course.id === id)!;
                    setSignupAmount(courseToDelete.amountOfSignups!);
                    setDeleteId(id as number);
                    setDeleteDialogOpen(state);
                    return;
                }

                setDeleteDialogOpen(state);
                setDeleteId(null);
                break;
            default:
                break;
        }
    }, [deletedCourses]);

    const columns: GridColDef[] = getDeletedCoursesColumns(
        translateHandler,
        dialogHandler,
    );

    const singleRestoreHandler = useCallback((): void => {
        dialogHandler(false);
        setLoading(true);
        restoreCourse(restoreId!)
            .then((message: string): void => {
                toast.success(t(message), {
                    hideProgressBar: true,
                });
                refetch();
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
            })
            .finally((): void => setLoading(false));
    }, [dialogHandler, restoreId, t, refetch]);

    const restoreHandler = useCallback((): void => {
        setRestoreDialogOpen(false);
        setLoading(true);
        restoreCourses(rowSelectionModel)
            .then((message: string): void => {
                toast.success(t(message), {
                    hideProgressBar: true,
                });
                refetch();
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
            })
            .finally((): void => setLoading(false));
    }, [rowSelectionModel, t, refetch]);

    const deleteHandler = useCallback((): void => {
        setDeleteInput('');
        dialogHandler(false, deleteId!, 'delete');
        setLoading(true);
        deleteCourse(deleteId!, true)
            .then((message: string): void => {
                toast.success(t(message), {
                    hideProgressBar: true,
                });
                refetch();
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
            })
            .finally((): void => setLoading(false));
    }, [dialogHandler, deleteId, t, refetch]);

    return (
        <div className="admin-page-container deleted-courses-container">
            <Backdrop open={loading}
                      sx={{ zIndex: (theme: Theme) => theme.zIndex.drawer + 1 }}
            >
                <CircularProgress sx={{ color: 'var(--background-color)' }} />
            </Backdrop>
            <div className="admin-page-wrapper deleted-courses-wrapper">
                <Link to="/manage-courses" className="back-link">
                    <BackIcon />
                </Link>
                <div className="admin-page-title deleted-courses-title">{t('deletedCourses')}</div>
                <div className="admin-page-content deleted-courses-content">
                    <div className="admin-page-controls deleted-courses-controls">
                        {rowSelectionModel.length > 0 &&
                            <Button variant="contained"
                                    className="action-button"
                                    onClick={() => setRestoreDialogOpen(true)}
                            >{t('restoreSelected')}</Button>
                        }
                        <Dialog
                            open={singleRestoreDialogOpen}
                            onClose={() => setSingleRestoreDialogOpen(false)}
                            aria-labelledby="restore-single-dialog-title"
                        >
                            <DialogTitle id="restore-single-dialog-title">{t('restoreCourse?')}</DialogTitle>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    className="action-button"
                                    onClick={() => setSingleRestoreDialogOpen(false)}>{t('cancel')}</Button>
                                <Button
                                    variant="contained"
                                    className="action-button"
                                    onClick={singleRestoreHandler}
                                    autoFocus
                                    disabled={!singleRestoreDialogOpen}>{t('yes')}</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={restoreDialogOpen}
                            onClose={() => setRestoreDialogOpen(false)}
                            aria-labelledby="restore-dialog-title"
                        >
                            <DialogTitle id="restore-dialog-title">{t('restoreSelectedCourses?')}</DialogTitle>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    className="action-button"
                                    onClick={() => setRestoreDialogOpen(false)}>{t('cancel')}</Button>
                                <Button
                                    variant="contained"
                                    className="action-button"
                                    onClick={restoreHandler}
                                    autoFocus
                                    disabled={!restoreDialogOpen}>{t('yes')}</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={deleteDialogOpen}
                            onClose={() => dialogHandler(false, deleteId!, 'delete')}
                            aria-labelledby="delete-dialog-title"
                        >
                            <DialogTitle id="delete-dialog-title">{t('deleteCoursePermanently?')}</DialogTitle>
                            {signupAmount > 0 &&
                                <DialogContent>
                                    <DialogContentText id="delete-dialog-type-delete">
                                        {t(`courseTypePermanentlyDelete.${signupAmount > 1 ? 'multiple' : 'single'}`, { amount: signupAmount })}
                                    </DialogContentText>
                                    <TextField
                                        id="delete-input"
                                        value={deleteInput}
                                        onChange={(e) => setDeleteInput(e.target.value)}
                                        sx={{
                                            background: '#fff',
                                            position: 'relative',
                                            marginTop: '12px',
                                        }}
                                    />
                                </DialogContent>
                            }
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    className="action-button"
                                    onClick={() => dialogHandler(false, deleteId!, 'delete')}>{t('cancel')}</Button>
                                <Button
                                    variant="contained"
                                    className="action-button"
                                    onClick={deleteHandler}
                                    autoFocus
                                    disabled={signupAmount > 0 && deleteInput !== 'permanently delete'}>{t('yes')}</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    <div className="admin-page-grid deleted-courses-grid">
                        <DataGrid
                            loading={isLoading}
                            columns={columns}
                            rows={deletedCourses ?? []}
                            slots={{
                                toolbar: Toolbar,
                                loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
                            }}
                            sx={{
                                color: 'var(--background-color)',
                                border: 'none',
                                '& .MuiDataGrid-toolbarContainer': {
                                    background: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    color: 'var(--background-color)',
                                    padding: '10px',
                                },
                                '& .MuiDataGrid-topContainer::after': {
                                    background: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-main > *:first-of-type': {
                                    borderRadius: 0,
                                },
                                '& .MuiDataGrid-topContainer': {
                                    borderBottom: '1px solid var(--background-color)',
                                },
                                '& .MuiDataGrid-columnHeaders [role="row"]': {
                                    background: 'var(--text-color)',
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '& .MuiDataGrid-columnSeparator:hover': {
                                    color: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-withBorderColor': {
                                    borderColor: 'var(--background-color)',
                                },
                                '& .MuiCheckbox-root': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiCheckbox-root.Mui-checked': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-virtualScrollerContent .MuiCheckbox-root': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-virtualScrollerContent .MuiCheckbox-root.Mui-checked': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiDataGrid-overlayWrapperInner > div': {
                                    background: 'var(--text-color)',
                                },
                                '& .MuiDataGrid-filler': {
                                    display: 'none',
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    background: 'var(--text-color)',
                                    borderColor: 'var(--background-color)',
                                },
                                '& .MuiTablePagination-toolbar': {
                                    color: 'var(--background-color)',
                                },
                                '& .MuiTablePagination-selectIcon': {
                                    color: 'var(--background-color)',
                                },
                            }}
                            slotProps={{
                                loadingOverlay: {
                                    sx: {
                                        backgroundColor: 'var(--text-color)',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: 'var(--button-color)',
                                        },
                                    },
                                },
                                pagination: {
                                    labelRowsPerPage: t('rowsPerPage'),
                                },
                            }}
                            initialState={{
                                pagination: {paginationModel: {pageSize: 20}},
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        description: false,
                                        richTextDescription: false,
                                        assignedTo: false,
                                        linkedId: false,
                                        assignedToGroups: false,
                                        parentId: false,
                                    },
                                },
                            }}
                            localeText={getGridLocalization(translateHandler, 'noDeletedCourses')}
                            pageSizeOptions={[20, 50, 100]}
                            onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel): void => {
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                            rowSelectionModel={rowSelectionModel}
                            checkboxSelection={true}
                            onFilterModelChange={(newFilterModel: GridFilterModel): void => {
                                setFilterModel(newFilterModel);
                            }}
                            filterModel={filterModel}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default DeletedCourses;
