"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatTime = formatTime;
function formatTime(date, separator) {
    const dateObject = new Date(date);
    const formatter = new Intl.DateTimeFormat('no-NO', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
    return formatter.format(dateObject).slice(0, -3).replace(',', separator);
}
