import React, { useState, useCallback, useEffect } from 'react';
import { getLoginState } from '../../api/Admin/Utils';
import { AuthContext } from '../../contexts/Admin/AuthContext';
import { toast } from 'react-toastify';
import type { User } from '../../../../server/types/admin/core';

const AuthContextProvider = ({ children }: { children: React.ReactElement }) => {
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [user, setUser] = useState<null | User>(null);

    const updateLoginState = useCallback(async (user?: User): Promise<void> => {
        if (user) {
            setLoggedIn(true);
            setUser(user);
            return;
        }

        try {
            const { loggedIn: loggedState, user } = await getLoginState();
            setLoggedIn(loggedState);
            user && setUser(user);

            const token: string | null = localStorage.getItem('admin-auth-token');
            if (token && !loggedState) {
                localStorage.removeItem('admin-auth-token');
            }
        } catch (err: any) {
            const { responseText } = err.request;
            const errorMessage: string = responseText ? responseText : err.message;
            toast.error(errorMessage, {
                hideProgressBar: true,
            });
        }
    }, []);

    useEffect((): void => {
        updateLoginState();
    }, [updateLoginState])

    return <AuthContext.Provider value={{
        loggedIn,
        updateLoginState,
        user,
    }}>{children}</AuthContext.Provider>
}

export default AuthContextProvider;
