import React, { memo, useContext, useState, useCallback } from 'react';
import {
    FormControl,
    TextField,
    Button,
} from '@mui/material';
import { useNavigate, useRouterState } from '@tanstack/react-router';
import { AxiosError } from 'axios';
import { loginUser } from '../../api/Admin/Utils';
import { validEmail } from '../../helpers/Regex';
import { AuthContext } from '../../contexts/Admin/AuthContext';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import type { LoginResponse } from '../../../../server/types/admin/core';
import '../../styles/Admin/Login.css';

const Login = memo(function Login(): React.ReactElement {
    const { updateLoginState } = useContext(AuthContext);
    const navigate = useNavigate({ from: '/admin' });
    const router = useRouterState();
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [invalidFields, setInvalidFields] = useState<{ [key: string]: boolean }>({
        email: false,
        password: false,
    });

    const validateFields = useCallback((): boolean => {
        const emailInvalid: boolean = email === '' || !validEmail.test(email);
        const passwordInvalid: boolean = password === '';

        setInvalidFields({
            email: emailInvalid,
            password: passwordInvalid,
        });

        return emailInvalid || passwordInvalid;
    }, [email, password]);

    const submitHandler = useCallback((): void => {
        if (validateFields()) {
            return;
        }

        loginUser(email, password)
            .then(({ user, token }: LoginResponse): void => {
                localStorage.setItem('admin-auth-token', token!);
                updateLoginState(user);
                navigate({
                    to: (router.location.search as { redirect: string })?.redirect ?? '/dashboard',
                });
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
            });
    }, [
        validateFields,
        email,
        password,
        updateLoginState,
        navigate,
        router.location.search,
        t,
    ]);

    const keyUpHandler = useCallback((event: React.KeyboardEvent): void => {
        if (event.key === 'Enter') {
            submitHandler();
        }
    }, [submitHandler]);

    return (
        <div className="login-container">
            <div className="login-form-wrapper">
                <FormControl sx={{ gap: '30px' }}>
                    <TextField
                        id="email-input"
                        label={t('email')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={invalidFields.email}
                        helperText={invalidFields.email ? t('emailInvalid') : ''}
                        sx={{ background: '#fff', position: 'relative' }}
                        onKeyUp={keyUpHandler}
                    />
                    <TextField
                        id="password-input"
                        label={t('password')}
                        type="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={invalidFields.password}
                        helperText={invalidFields.password ? t('passwordInvalid') : ''}
                        sx={{ background: '#fff', position: 'relative' }}
                        onKeyUp={keyUpHandler}
                    />
                    <Button variant="contained"
                            onClick={submitHandler}
                            onKeyUp={keyUpHandler}
                    >{t('login')}</Button>
                </FormControl>
            </div>
        </div>
    );
});

export default Login;
