import React, { memo, useCallback } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { Image, Button } from 'react-bootstrap';
import '../styles/ImageHero.css';

const ImageHero = memo(function ImageHero(
    {
        title,
        subtitle,
        image,
        label,
        url,
    }: {
        title: string,
        subtitle: string,
        image: string,
        label: string,
        url: string,
    }
): React.ReactElement {
    const navigate = useNavigate({ from: window.location.pathname });
    const isExternal: boolean = url.includes('http:') || url.includes('https:');

    const buttonHandler = useCallback(() => {
        if (isExternal) {
            window.location.href = url;
            return;
        }

        navigate({ to: url });
    }, [url, navigate, isExternal]);

    return (
        <div className="image-hero-container">
            <Image
                src={image}
                alt={title}
                className="image-hero-banner"
            />
            <div className="image-hero-content">
                <h1 className="image-hero-title display-5 fw-bold">{title}</h1>
                <p className="image-hero-subtitle lead mb-4">{subtitle}</p>
                <Button
                    variant="primary"
                    onClick={buttonHandler}
                >{label}</Button>
            </div>
        </div>
    );
});

export default ImageHero;
