import React, { memo } from 'react';
import { Skeleton } from '@mui/material';
import CourseItem from './CourseItem';
import '../styles/CourseListSkeleton.css';

const CourseListSkeleton = memo(function CourseListSkeleton(): React.ReactElement {
    return (
        <div className="course-list-wrapper">
            <div className="course-list">
                <div className="course-list-content">
                    <Skeleton
                        variant="rounded"
                        animation="wave"
                        className="course-item-skeleton"
                    >
                        <CourseItem/>
                    </Skeleton>
                    <Skeleton
                        variant="rounded"
                        animation="wave"
                        className="course-item-skeleton"
                    >
                        <CourseItem/>
                    </Skeleton>
                    <Skeleton
                        variant="rounded"
                        animation="wave"
                        className="course-item-skeleton"
                    >
                        <CourseItem/>
                    </Skeleton>
                </div>
            </div>
        </div>
    );
});

export default CourseListSkeleton;
