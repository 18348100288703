import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    TextField,
    Button,
    FormControl,
    FormHelperText,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { importDiplomaFiles } from '../../api/Admin/Utils';
import { toast } from 'react-toastify';
import { SharePointConfiguration as Configuration } from '../../../../server/types/admin/core';
import { AxiosError } from 'axios';

const SharePointConfiguration = memo(function SharePointConfiguration(
    {
        configuration,
        setConfiguration,
        setLoading,
    }: {
        configuration: Configuration,
        setConfiguration: Function,
        setLoading: Function,
    }
): React.ReactElement {
    const { t } = useTranslation();
    const sharePointChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const [, fieldName] = event.target.name.split('.');

        setConfiguration({
            ...configuration,
            [fieldName]: event.target.value,
        });
    }, [setConfiguration, configuration]);
    const sharePointFileChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
        const certificatePrivateKey = event.target.files![0];

        if (!certificatePrivateKey) {
            return;
        }

        if (certificatePrivateKey.name.slice(-4) === '.pem') {
            setConfiguration({
                ...configuration,
                certificatePrivateKey,
            });
        }
    }, [setConfiguration, configuration]);
    const importHandler = useCallback((): void => {
        setLoading(true);
        importDiplomaFiles()
            .then((message: string): void => {
                toast.success(t(message), {
                    hideProgressBar: true,
                });
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
            })
            .finally(() => setLoading(false));
    }, [setLoading, t]);

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="sharepoint-content"
                id="sharepoint-header"
            >
                SharePoint
            </AccordionSummary>
            <AccordionDetails>
                <div className="configuration-content">
                    <div className="row">
                        <TextField
                            id="share-point-client-id-input"
                            label={t('clientId')}
                            value={configuration.clientId}
                            name="sharePoint.clientId"
                            onChange={sharePointChangeHandler}
                            sx={{background: '#fff', position: 'relative'}}
                        />
                    </div>
                    <div className="row">
                        <TextField
                            id="share-point-tenant-id-input"
                            label={t('tenantId')}
                            value={configuration.tenantId}
                            name="sharePoint.tenantId"
                            placeholder="contoso.onmicrosoft.com"
                            onChange={sharePointChangeHandler}
                            sx={{background: '#fff', position: 'relative'}}
                        />
                    </div>
                    <div className="row">
                        <FormControl>
                            <Button variant="contained"
                                    className="action-button"
                                    component="label"
                            >
                                {t('uploadCertificatePrivateKey')}
                                <input
                                    accept=".pem"
                                    id="share-point-certificate-private-key-input"
                                    type="file"
                                    onChange={sharePointFileChangeHandler}
                                    hidden
                                />
                            </Button>
                            {configuration.certificatePrivateKey &&
                                <FormHelperText
                                    className="share-point-certificate-private-key-helper-text helper-text"
                                >
                                    {typeof configuration.certificatePrivateKey === 'string'
                                        ? configuration.certificatePrivateKey
                                        : configuration.certificatePrivateKey.name
                                    }
                                </FormHelperText>
                            }
                            <FormHelperText className="share-point-certificate-helper-text helper-text">
                                {t('sharePointCertificateHelperText')}&nbsp;
                                <a
                                    href="https://pnp.github.io/pnpjs/getting-started/#authentication"
                                    target="_blank"
                                    rel="noreferrer"
                                >{t('here')}</a>
                            </FormHelperText>
                        </FormControl>
                    </div>
                    <div className="row">
                        <FormControl>
                            <TextField
                                id="share-point-certificate-fingerprint-input"
                                label={t('certificateFingerprint')}
                                value={configuration.certificateFingerprint}
                                name="sharePoint.certificateFingerprint"
                                onChange={sharePointChangeHandler}
                                sx={{background: '#fff', position: 'relative'}}
                            />
                            <FormHelperText
                                className="share-point-certificate-fingerprint-helper-text helper-text"
                            >
                                {t('sharePointCertificateFingerprintHelperText')}
                            </FormHelperText>
                        </FormControl>
                    </div>
                    <div className="row">
                        <TextField
                            id="share-point-base-url-input"
                            label={t('baseUrl')}
                            value={configuration.baseUrl}
                            name="sharePoint.baseUrl"
                            placeholder="https://contoso.sharepoint.com"
                            onChange={sharePointChangeHandler}
                            sx={{background: '#fff', position: 'relative'}}
                        />
                    </div>
                    <div className="row">
                        <TextField
                            id="share-point-sites-path-input"
                            label={t('sitesPath')}
                            value={configuration.sitesPath}
                            name="sharePoint.sitesPath"
                            placeholder="sites/Demo"
                            onChange={sharePointChangeHandler}
                            sx={{background: '#fff', position: 'relative'}}
                        />
                    </div>
                    <div className="row">
                        <TextField
                            id="share-point-diploma-folder-path-input"
                            label={t('diplomaFolderPath')}
                            value={configuration.diplomaFolderPath}
                            name="sharePoint.diplomaFolderPath"
                            placeholder="Shared Documents/Diploma Files"
                            onChange={sharePointChangeHandler}
                            sx={{background: '#fff', position: 'relative'}}
                        />
                    </div>
                    <FormControl>
                        <FormHelperText className="before-share-point-import-helper-text helper-text">
                            {t('beforeSharePointImportHelperText')}
                        </FormHelperText>
                        <Button variant="contained"
                                className="action-button"
                                onClick={importHandler}
                        >
                            {t('importDiplomaFiles')}
                        </Button>
                        <FormHelperText className="after-share-point-import-helper-text helper-text">
                            {t('afterSharePointImportHelperText')}
                        </FormHelperText>
                    </FormControl>
                </div>
            </AccordionDetails>
        </Accordion>
    );
});

export default SharePointConfiguration;
