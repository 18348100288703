import React, { memo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetConfigurations, saveConfigurations } from '../../api/Admin/Utils';
import { toast } from 'react-toastify';
import {
    Backdrop,
    Button,
    CircularProgress,
    Theme,
} from '@mui/material';
import SharePoint from './SharePointConfiguration';
import { AxiosError } from 'axios';
import { SharePointConfiguration } from '../../../../server/types/admin/core';
import '../../styles/Admin/Configurations.css';

const Configurations = memo(function Configurations(): React.ReactElement {
    const { t } = useTranslation();
    const [sharePoint, setSharePoint] = useState<SharePointConfiguration>({
        clientId: '',
        tenantId: '',
        certificatePrivateKey: '',
        certificateFingerprint: '',
        baseUrl: '',
        sitesPath: '',
        diplomaFolderPath: '',
    });
    const [loading, setLoading] = useState<boolean>(false);
    const { data: configuration, isLoading, error } = useGetConfigurations();

    if (error) {
        const { responseText } = error.request;
        const errorMessage: string = responseText ? responseText : error.message;
        toast.error(t(errorMessage), {
            hideProgressBar: true,
        });
    }

    const setConfigurationData = useCallback(() => {
        configuration?.sharePoint && setSharePoint((prevValue: SharePointConfiguration) => {
            return { ...prevValue, ...configuration?.sharePoint };
        });
    }, [configuration]);

    useEffect(() => {
        if (configuration) {
            setConfigurationData();
        }
    }, [configuration, setConfigurationData]);

    const saveHandler = useCallback(() => {
        setLoading(true);
        saveConfigurations({
            sharePoint,
        })
            .then((message: string): void => {
                toast.success(t(message), {
                    hideProgressBar: true,
                });
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
            })
            .finally((): void => setLoading(false));
    }, [sharePoint, t]);

    return (
        <div className="admin-page-container configurations-container">
            <Backdrop open={isLoading || loading}
                      sx={{zIndex: (theme: Theme) => theme.zIndex.drawer + 1}}
            >
                <CircularProgress sx={{color: 'var(--background-color)'}}/>
            </Backdrop>
            <div className="admin-page-wrapper configurations-wrapper">
                <div className="admin-page-title configurations-title">{t('configurations')}</div>
                <div className="admin-page-content configurations-content">
                    <div className="admin-page-controls configurations-controls">
                        <Button variant="contained"
                                className="action-button"
                                onClick={saveHandler}
                        >{t('save')}</Button>
                    </div>
                    <div className="admin-page-list configurations-list">
                        <SharePoint
                            configuration={sharePoint}
                            setConfiguration={setSharePoint}
                            setLoading={setLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Configurations;
